<template>

  <div>

    <select class="locale-switcher" v-model="$i18n.locale"  @change="changeLanguage">
      <option value="en">🌐 En</option>
      <option value="ru">🌐 Ру</option>
      <option value="uz">🌐 Uz</option>
    </select>
  </div>
</template>
<script>

import axios from "axios";

export default {
  methods: {
    setLocale(locale) {
      this.$i18n.locale = locale;
    },
    changeLanguage(){
      axios.post('v1/influencer/users/lang', { lang: this.$i18n.locale })
          .then(() => {
          })
          .catch(error => {
            console.log(error)
          });
    }
  }
};
</script>

<style scoped>
.nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: left;
  padding: 1rem;
  color: #fff;
  background-color: #3d536a;
}
.nav__start,
.nav__end {
  display: flex;
  align-items: center;
}
.nav img {
  margin-right: 1rem;
}
.nav a {
  margin-right: 1.5rem;
  font-weight: bold;
  color: #fff;
  text-decoration: none;
}
</style>
