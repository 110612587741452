<template>
  <v-simple-table>
    <template v-slot:default>
      <thead>
      <tr>
        <th class="text-left">
          {{ $t('tableHeaders.date') }}
        </th>  <th class="text-left">
          {{ $t('tableHeaders.uid') }}
        </th>
        <th class="text-left">
          {{ $t('tableHeaders.amount') }}
        </th>
        <th class="text-left">
          {{ $t('tableHeaders.currency') }}
        </th>
        <th class="text-left">
          {{ $t('tableHeaders.type') }}
        </th>
        <th class="text-left">
          {{ $t('tableHeaders.status') }}
        </th>
      </tr>
      </thead>
      <tbody>
      <tr v-if='Object.keys(new_request).length >0' class="active">
        <td>{{new_request.user_date}}</td>
        <td>{{new_request.uid}}</td>
        <td><b>{{ new_request.amount }}</b>.unit</td>
        <td>{{ new_request.currency }}</td>
        <td>{{ new_request.type }}</td>

        <td>
          <v-progress-circular
              v-if="new_request.status_id === 1"
              :size="30"
              color="primary"
              indeterminate
          ></v-progress-circular>
          <v-alert
              v-if="new_request.status_id === 3"
              dense
              text
              type="success"
          >
            {{ $t('accept') }}
          </v-alert>
          <v-alert
              v-if="new_request.status_id === 2"
              dense
              text
              type="error"
          >
            {{ $t(new_request.msg) }}
          </v-alert>
          <v-alert
              v-if="new_request.status_id === 5"
              dense
              text
              border="left"
              type="warning"
          >
            {{ $t(new_request.msg) }}
          </v-alert>
          <v-alert
              v-if="new_request.status_id === 6"
              dense
              text
              border="left"
              type="warning"
          >
            {{ $t(new_request.msg) }}
          </v-alert>
        </td>
      </tr>

      <tr
          v-for="(request, i) in all_request"
          :key="i"
      >
        <td>{{ request.user_date }}</td>
        <td>{{ request.uid }}</td>

        <td><b>{{ request.amount }}</b>.unit</td>
        <td>{{ request.currency }}</td>
        <td>{{ request?.type }}</td>
        <td>
          <div>
            <v-progress-circular
                v-if="request.status_id === 1"
                :size="30"
                color="primary"
                indeterminate
            ></v-progress-circular>
            <v-alert
                v-if="request.status_id === 3"
                dense
                text
                type="success"
            >
              {{ $t('accept') }}
            </v-alert>
            <v-alert
                v-if="request.status_id === 2"
                dense
                text
                type="error"
            >
              {{ $t('invalidAmountMessage') }}
            </v-alert>
            <v-alert
                v-if="request.status_id === 6"
                dense
                text
                border="left"
                type="error"
            >
              {{ $t('paymentNotReceived') }}
            </v-alert>
            <v-alert
                v-if="request.status_id === 5"
                dense
                text
                border="left"
                type="warning"
            >
              {{ $t('excessiveRequestWarning') }}
            </v-alert>
          </div>
        </td>
        <td>

        </td>

      </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>
<script>
export default {
  name: "requestPayment",
  props: ['all_request'],
  computed:{
    new_request: {
      get (){
        return this.$store.getters["payment/getNewRequest"]
      }
    },
  }
}
</script>

<style scoped>

</style>