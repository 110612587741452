"use strict"
export default {
    state: {
        allow: true,
        loading: false,
        error: null,
        notification: null,
        snack: false,
    },
    mutations: {
        setAllow (state, payload) {
            state.allow = payload;
        },
        setLoading (state, payload) {
            state.loading = payload;
        },
        setNotification (state, payload) {
            state.notification = payload;
        },
        setError (state, payload) {
            state.error = payload

        },
        cleanError (state) {
            state.error = null;
        },
        cleanNotification (state) {
            state.notification = null;
        },
        setSnackBar (state, snack){
            state.snack = snack;
        }
    },
    actions: {
        setAllow ({commit}, payload) {
            commit('setAllow', payload);
        },
        setLoading ({commit}, payload) {
            commit('setLoading', payload);
        },
        setError ({commit}, payload) {
             commit('setError', payload);
        },
        cleanError ({commit}) {
            commit('cleanError');
        },
        cleanNotification ({commit}) {
            commit('cleanNotification');
        },
        setSnackBar ({commit},payload) {
            commit('setSnackBar',payload);
        }
    },
    getters: {
        allow (state) {
            return state.allow;
        },
        loading (state) {
            return state.loading;
        },
        error (state) {
            return state.error;
        },
        notification (state) {
            return state.notification;
        },
        getSnackBar (state) {
            return state.snack;
        }
    }
}
