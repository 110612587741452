import axios from "axios";
// import {NewRequest} from "@/classes/NewRequest";

const  state = {
    items: null,
    all_request: {},
    new_request: {},
    rate: {},
    defaultCur: 'USD',
    userCur: {},
    convertCur: {},
    error: null,
}

const actions = {
    async addRequest ({commit}, {data, conf, id}) {
        try {
            await axios.post('v1/influencer/payment/addPayment', data, conf).then((response) => {
                if (response.status === 200){
                    alert('Ваш запрос обрабатывается дождитесь результата!')
                }
            });

            // Подписка на канал и добавление обработчика после успешной подписки
            const channel = window.pusher.subscribe(`private-PaymentGet.${id}`);

            channel.bind('payment_geted', (e) => {

                commit('SET_NEW_REQUEST', e);
                if (e.status_id === 3){
                    location.reload();
                }

            });

        } catch (error) {
            console.error(error);
            // Обработка ошибки, если не удалось выполнить запрос
            // commit('SET_ERROR', error);
        }
    },
    async getRequest ({commit}) {
         await axios.get('v1/influencer/payment/getPayment').then((response)=>{
             commit('SET_All_REQUEST',response.data.payment)

         })
    },
    async getPaymentInfo ({commit}) {
         await axios.get('v1/influencer/payment/paymentInfo').then((response)=>{

             commit('SET_ITEMS',response.data.data)
         })


    },
    async exchangeRate ({commit}) {
         await axios.get('v1/influencer/payment/exchangeRate').then((response)=>{

             commit('SET_RATES',response.data)
         })


    },
    /*updateUserBalance(balance) {
        // Вызов действия setUserBalance с использованием this.$store.dispatch
        this.$store.commit('setUserBalance',balance)
    }*/
};
const getters = {
    items (state) {
        return state.items;
    },
    getRate (state) {
        return state.rate;
    },
    getAllRequest (state) {
        return state.all_request;
    },
    getNewRequest (state) {
        return state.new_request;
    },
    getUserCur (state) {
        return state.userCur;
    },
    getDefaultCur (state) {
        return state.defaultCur;
    },
    getConvertCur (state) {
        return state.convertCur;
    },
    getError (state) {
        return state.error;
    },
};
const mutations = {
    SET_ITEMS:(state, payload ) =>{
        state.items = payload
    },
    SET_RATES:(state, payload ) =>{
        state.rate = payload
    },
    SET_NEW_REQUEST:(state, payload ) =>{
        state.new_request = payload

    },
    SET_All_REQUEST:(state, payload ) =>{
        state.all_request = payload
    },
    SET_ERROR:(state, payload ) =>{
        state.error = payload
    },
    SET_USER_CUR:(state,payload) =>{
        state.userCur = payload
    },
    SET_CONVERT_CUR:(state,payload) =>{
        state.convertCur = payload
    },
    SET_DEFAULT_CUR:(state,payload) =>{
        state.defaultCur = payload
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}
