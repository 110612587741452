<template>
  <div>
    <h2>Payment Successful</h2>
    <!-- Здесь можете добавить содержимое страницы об успешной оплате -->
  </div>
</template>

<script>
export default {
  name: 'SuccessPage',
};
</script>
