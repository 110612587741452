import axios from "axios";
import {Preview} from "@/classes/preview";
const  state = {
    previews:  [],
    selectedItem: [],
    selectedPrice: '',
    selectedCount: '',

};
const  methods = {


};
const  actions = {
    async addPreview({ commit }, payload) {
        try {
            const isIdUnique = !state.previews.some(preview => preview.id === payload.id);
            if (isIdUnique) {
                await axios.post('v1/influencer/preview', payload)
                const privateChannelName = `private-Preview.${payload.us}`;
                const privateChannel = window.pusher.subscribe(privateChannelName);
                privateChannel.bind('stock_preview', function(data) {
                    console.log(data)
                    if (data.success) {
                        commit('SET_PREVIEWS', data)
                    } else {
                        commit('SET_ERROR', data)
                    }
                });
            } else {
                console.error('Preview with the same id already exists.');
            }
        } catch (error) {
            console.error('Error while adding preview:', error);
            commit('SET_ERROR', error);
        }
    },
};
const mutations = {
    SET_PREVIEWS: (state, payload) => {

        try {
            if (state.previews.length > 0) {
                for (let i = 0; i < state.previews.length; i++) {
                    switch (payload.category_name) {
                        case 'envatoelements':
                        case 'element_envato':
                        case 'epidemicsound':
                        case 'alamy':
                        case 'Other':
                            if (state.previews[i].id === payload.id) {
                                Object.assign(state.previews[i], payload);
                            }
                            break;
                        default:
                            if (
                                parseInt(state.previews[i].id) === parseInt(payload.id) &&
                                state.previews[i].type_name === payload.type_name &&
                                state.previews[i].category_name === payload.category_name &&
                                state.previews[i].options === payload.options
                            ) {
                                Object.assign(state.previews[i], new Preview(payload));
                            }
                            break;
                    }
                }
                // localStorage.setItem('previews', JSON.stringify(state.previews));
            }
        } catch (exception) {
            console.error(exception);
        }
    },

    SET_PRE_PREVIEWS: (state, payload) => {

        // Check if the preview already exists in the state
        const existingPreview = state.previews.find(
            preview =>
                preview.id === payload.id &&
                preview.category_name === payload.category_name &&
                preview.type_name === payload.type_name &&
                preview.action === payload.action
        );

        // If the preview doesn't exist, add it to the array
        if (!existingPreview) {
            state.previews.push(new Preview(payload));
        }
    },
    setCurrentDownloading (state, data){

        for (let i = 0; i <  state.previews.length; i++) {
            if(state.previews[i].id === data.id && state.previews[i].resourse_category_types === data.type_name) {
                Object.assign(state.previews[i], data.progressBar)
            }
        }
    },
    SET_PRE_STATUS: (state,payload)=>{
        const categoriesToUpdate = ["element_envato","envatoelements", "alamy", "epidemicsound"];

        for (let i = 0; i <  state.previews.length; i++) {
            if (categoriesToUpdate.includes(payload.category_name)) {
                if(state.previews[i].id === payload.id) {
                    Object.assign(state.previews[i], payload )
                }
            }else {
                if( parseInt(state.previews[i].id) === parseInt( payload.id) &&
                    state.previews[i].type_name === payload.type_name &&
                    state.previews[i].category_name === payload.category_name &&
                    state.previews[i].options === payload.options) {
                    if (payload.error === true){
                        Object.assign(state.previews[i], payload)
                    }
                    if (payload.status !== 'failed'){
                        state.previews[i] = Object.assign(state.previews[i], payload)
                    }else {
                        state.previews[i] = Object.assign(state.previews[i], payload)
                    }
                }
            }

        }
    },
    SET_PRE_STATUS_BUYING: (state, payload) => {
        const categoriesToUpdate = ["envatoelements", "alamy", "epidemicsound"];

        for (let i = 0; i < state.selectedItem.length; i++) {
            const selectedItem = state.selectedItem[i];
            const payloadItem = payload[i];

            if (categoriesToUpdate.includes(payloadItem.category_name)) {
                if (selectedItem.id === payloadItem.id) {
                    Object.assign(selectedItem, payloadItem);
                }

                for (let x = 0; x < state.previews.length; x++) {
                    const preview = state.previews[x];
                    if (parseInt(preview.id) === parseInt(payloadItem.id)) {
                        Object.assign(preview, payloadItem);
                    }
                }
            } else {
                if (parseInt(selectedItem.id) === parseInt(payloadItem.id)) {
                    Object.assign(selectedItem, payloadItem);
                }

                for (let x = 0; x < state.previews.length; x++) {
                    const preview = state.previews[x];
                    if (parseInt(preview.id) === parseInt(payloadItem.id)) {
                        Object.assign(preview, payloadItem);
                    }
                }
            }
        }
    },

    SET_SELECT_ITEM: (state,payload)=>{
        state.selectedItem =  payload

    },
    SET_SELECTED_ITEM_CODE: (state,payload)=>{

        for (let i = 0; i <  state.selectedItem.length; i++) {
            if(parseInt(state.selectedItem[i].id) === parseInt(payload.id)) {
                state.selectedItem[i].code = payload.code
            }
        }

    },
    SET_ERROR: (state,payload)=>{
        for (let i = 0; i <  state.previews.length; i++) {
            if( state.previews[i].id === payload.id &&
                state.previews[i].type_name === payload.type_name &&
                state.previews[i].category_name === payload.category_name) {
                Object.assign(state.previews[i], payload)
            }
        }
    }
};
const  getters = {
    getPreviews (state) {
        // const storedPreviews = localStorage.getItem('previews');

        // If there are stored previews, parse and return them; otherwise, return the state previews
        return   state.previews;
    },
    getselectedItem(state){
        return state.selectedItem ;
    },
    getselectedItemCount(state){
        return state.selectedItem ;
    },
    getselectedPrice(state){
        return state.selectedPrice ;
    }
};

export default {
    namespaced: true,
    state,
    methods,
    actions,
    mutations,
    getters
}
