// import {Orders} from "@/classes/orders";
import shared from "@/store/shared";
import axios from "axios";

const state =  {
    order: {},
    current_page: 1, // Инициализируйте текущую страницу значением по умолчанию
    last_page: 1, // Инициализируйте last_page значением по умолчанию

};
const actions = {
    async getOrderList ({commit}, pageNumber){
        try {
            const response = await axios.get('v1/influencer/orders?page=' + pageNumber, { withCredentials: true });
            commit('setOrders', response.data);
        } catch (error) {
            commit('setError', error.message);
        }
    },
    async getOrderLink({ commit }, data) {
        try {
            await axios.post('v1/influencer/orders/file', data, { responseType: 'blob' })
                .then(response => {
                    if (response.headers['content-type'] === 'application/json') {
                        const reader = new FileReader();
                        reader.onload = () => {
                            try {
                                const jsonData = JSON.parse(reader.result);
                                window.open(jsonData.link)
                            } catch (error) {
                                console.error('Error parsing JSON:', error);
                            }
                        };
                        reader.readAsText(response.data);
                    }else{
                        const originalHeaders = response.headers;
                        // Access specific headers

                        const contentType = originalHeaders['content-type'];
                        const blob = new Blob([response.data], {
                            type: contentType
                        });
                        const typeToExtensionMap = {
                            'x-eps': 'eps',
                            'postscript': 'eps',
                            'pdf': 'ai',
                            'quicktime': 'mov',
                            // Добавьте другие соответствия здесь
                        };
                        const extensionMatch = blob.type.match(/(eps|jpeg|jpg|psd|png|mov|mkv|mp4|zip|rar|wav|mp3|bmp|tiff|mpeg|postscript|ai|pdf|quicktime)/);
                        const extensionKey = extensionMatch[0];

                        let actualExtension = typeToExtensionMap[extensionKey] || extensionKey;


                        const file = new File([response.data], data.id + '.' + actualExtension, {
                            type: response.data.type,
                        });

                        const fileURL = URL.createObjectURL(file);
                        const fileLink = document.createElement('a');
                        fileLink.href = fileURL;
                        fileLink.download = file.name;
                        document.body.appendChild(fileLink);
                        fileLink.click();
                        URL.revokeObjectURL(fileLink.href);
                    }

                })


        } catch (error) {
            commit('setError', error.message);
        }
    }
};

const mutations = {
    setCurrentPage (state, data){
        state.current_page = data
    },
    setCurrentDownloading (state, data){
        const index = state.order.data.findIndex(item => item.id === data.id && item.resourse_category_types === data.type_name);
        if (index !== -1) {
            state.order.data[index].progressBar = true;
        }
    },
    setOrders (state, data){
        state.order = data
        state.last_page = data.meta.last_page
    },
    setError (state, data){
        shared.state.error = data
    }
};
const getters = {
    allOrders(state) {
        return state.order.data || [];
    }


};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}