<template>
  <div>
    <h2>Payment Failed</h2>
    <!-- Здесь можете добавить содержимое страницы о неудачной оплате -->
  </div>
</template>

<script>
export default {
  name: 'FailPage',
};
</script>
