<template>
  <div>
    <navbar_pan :user="user"/>
    <main role="main">
      <router-view/>
    </main>
    <SupportChat v-if="chat === true" style="margin: 0"></SupportChat>
        <v-snackbar
            v-model="snackBar"
            :timeout="timeout"
        >
          {{ $t(text) }}

          <template v-slot:action="{ attrs }">
            <v-btn
                color="blue"
                text
                v-bind="attrs"
                @click="snackBar = false"
            >
              Close
            </v-btn>
          </template>
        </v-snackbar>
<!--    <Footers_vue></Footers_vue>-->
  </div>
</template>

<script >
import router from "@/router";
import navbar_pan from "@/components/Navbar.vue";
import axios from "axios";
import SupportChat from "@/components/Chat/SupportChat.vue";
// import Footers_vue from "@/components/Footers_vue.vue";
export default {
  async mounted() {
    const storedData = localStorage.getItem('jwt');

    if (storedData) {
      const parsedData = JSON.parse(storedData);
      const storedExpirationDate = new Date(parsedData.expires);

      if (storedExpirationDate > new Date()) {
        // The token is still valid
        const token = parsedData.token;

        try {
          const response = await axios.get('v1/influencer/user', {
            params: {
              scope: 'influencer',
            },
            headers: {
              Authorization: `Bearer ${token}`, // Access token from Vuex store
            },
          });

          // User data retrieved successfully
          await this.$store.commit('SET_USER', response.data.data);

          // Subscribe to Pusher channel
          window.pusher.subscribe(`private-Preview.${this.user.id}`).bind('previewEvent', eventData => {
            console.log(eventData);
            // Обработка события
          });

          this.setLocale(response.data.data.lang);
        } catch (error) {
          await this.handleLoginError();
          this.$store.commit('setError',error.response.data.error)
          this.snackBar = true
        }
      } else {
        // Token has expired
        localStorage.removeItem('jwt');
        await router.push('/login');
      }
    } else {
      // No token found
      await router.push('/login');
    }
  },
  data() {
    return {
      chat: false,
      timeout: 5000,
    }
  },
  components: {
    SupportChat,
    navbar_pan,
    // Footers_vue,
  },
  methods: {
    async handleLoginError() {
      // Handle errors during login, e.g., redirect to login page
      await router.push('/login');
    },
    setLocale(locale) {
      this.$i18n.locale = locale
    }
  },
  async created() {
    try {
      this.$store.commit('setLoading',false)
    }catch (e){
      await router.push('/login')
    }

  },
  name: 'HomePage', // Изменили имя компонента

  computed: {
    user: {
      get()  {
        return this.$store.getters.user
      },

    },
    text:{
      get() {
        return this.$store.getters.error
      }
    },
    snackBar:{
      get() {
        return this.$store.getters.getSnackBar
      },
      set(value) {
        return this.$store.commit('setSnackBar',value)
      }
    },
  },
};
</script>

