<template>
  <div class="text-center">
    <v-pagination
        v-model="currentPage"
        :length="lastPage"
        :total-visible="7"
    ></v-pagination>
  </div>

</template>

<script>

export default {
  props: ['store', 'collection'],
  watch: {
    currentPage(newVal ){
      this.paginatePage( newVal)
    }
  },

  computed: {

    currentPage: {
      get() {
        return this.$store.state.orders.current_page;
      },
      set(value) {
        this.$store.commit( 'orders/setCurrentPage', value );
      }
    },
    lastPage: {
      get() {
        return this.$store.state[this.store].last_page;
      }
    }
  },
  mutations: {

  },
  actions: {

  },
  methods: {
    paginatePage( pageNumber) {
      this.$store.dispatch('orders/getOrderList', pageNumber)
    }
  },


}
</script>

<style scoped>

</style>