<template>
  <div>
    <div class="d-flex align-center justify-center" style="flex-direction: column">
      <v-layout class="col-xs-12 col-sm-12 col-md-11 col-lg-9 ">
        <v-flex>
          <v-form
              ref="form"
              v-model="valid"
              validation
          >
            <v-toolbar>
              <template v-slot:extension>
                <v-tabs
                    v-model="currentItem"
                    fixed-tabs
                    slider-color="white"

                >
                  <v-tabs-slider></v-tabs-slider>
                  <v-tab
                      v-for="item in items"
                      @click="serSrc(item)"
                      :key="item"
                      :href="'#tab-' + item"
                  >
                    {{ item }}
                  </v-tab>
                  <v-menu
                      v-if="more?.length"
                      bottom
                      left
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                          text
                          class="align-self-center mr-4"
                          v-bind="attrs"
                          v-on="on"
                      >
                        {{ $t('MoreText') }}
                        <v-icon right>
                          mdi-menu-down
                        </v-icon>
                      </v-btn>
                    </template>

                    <v-list class="grey lighten-3">
                      <v-list-item
                          v-for="item in more"
                          :key="item"
                          v-model="category_name"
                          @click="addItem(item)"
                      >
                        {{ item }}

                      </v-list-item>
                    </v-list>

                  </v-menu>
                </v-tabs>
              </template>
            </v-toolbar>
            <v-tabs-items v-model="currentItem" centered>
              <v-tab-item
                  v-for="item in items.concat(more)"
                  :key="item"
                  :value="'tab-' + item"
              >
                <v-card flat>
                  <v-card-text>
                    <h2 v-if="category_name ">
                      {{ category_name }}

                      <span v-if="category_name === 'gettyimages'">{{ $t('MediumResolution') }}</span>
                      <span v-if="category_name === 'shutterstock' && defaultParameters === 'Music'">{{
                          $t('OnlyFullVersion')
                        }}</span>
                      {{ $t(defaultParameters) }}
                      <div class="d-flex">
                        <!--                       <form-->
                        <!--                           v-if="defaultParameters === 'Images' && category_name === 'adobestock'" >-->
                        <!--                         <div class="d-inline-flex pa-2">-->
                        <!--                           <input  type="radio" id="option1" name="options" v-model="options" value="png" checked>-->
                        <!--                           <label class="pa-2" for="option1"> PNG </label><br>-->
                        <!--                         </div>-->

                        <!--                         <div class="d-inline-flex pa-2">-->
                        <!--                           <input type="radio" id="option2" name="options" v-model="options" value="jpeg">-->
                        <!--                           <label class="pa-2" for="option2"> JPEG </label><br>-->
                        <!--                         </div>-->
                        <!--                       </form>-->
                        <form
                            v-if="defaultParameters === 'Video'">
                          <div class="d-inline-flex pa-2">
                            <input type="radio" id="option1" name="options" v-model="options" value="HD" checked>
                            <label class="pa-2" for="option1"> HD </label><br>
                          </div>

                          <div class="d-inline-flex pa-2">
                            <input type="radio" id="option2" name="options" v-model="options" value="4K">
                            <label class="pa-2" for="option2"> 4K </label><br>
                          </div>
                        </form>
                      </div>
                    </h2>

                    <h3 v-else>Select stock please</h3>
                    <v-col
                        class="d-inline pa-2"
                    >
                      <v-select
                          :items="getSelectItems(category_name)"
                          v-model="defaultParameters"
                          solo
                      ></v-select>
                    </v-col>

                    <form
                        v-if="loadedbalance !== false"
                    >
                      <v-col
                          cols="12"
                          md="12"
                      >
                        <v-textarea
                            v-model="id"
                            solo
                            name="id"
                            required
                            :rules="imageIdRule"
                            :placeholder="$t('PutId')"
                        ></v-textarea>
                      </v-col>
                      <v-col
                          class="d-flex"
                          cols="12"
                          sm="6"
                      >
                        <v-btn
                            class="mr-4"
                            @click="sendRequest"
                            :disabled="!valid "
                        >
                          {{ $t('SubmitButton') }}
                        </v-btn>
                      </v-col>
                    </form>

                  </v-card-text>
                </v-card>
              </v-tab-item>
            </v-tabs-items>
          </v-form>
        </v-flex>
      </v-layout>
      <PreviewComponent />
    </div>
  </div>
</template>
<script>
import PreviewComponent from "@/components/PreviewComponent.vue";
export default {
  name: "HomePage",
  data() {
    return {
      currentItem: 'shutterstock',
      id: '',
      category_name: 'shutterstock',
      count: '',
      options: null,
      balance: '',
      term: '',
      loadedbalance: true,
      messages: [],
      imageIdRule: [v => !!v || 'id is required'],
      props: {source: String},
      defaultParameters: 'Images',
      valid: false,
      tab: null,
      items: ['shutterstock', 'adobestock', 'depositphotos', 'rf123', 'istockphoto', 'dreamstime'],
      more: ['element_envato', 'pikbest', 'freepik','freepikvideo','vectorstock', 'motionarray', 'Pngtree', 'lovepik', 'gettyimages', 'Other'],
      parameters: ['Images', 'Video', 'Music'],
      exists: null,
    }
  },
  components: {
    PreviewComponent
  },
  computed: {
    preview: {
      get() {
        return this.$store.getters.getPreviews
      }
    },
    user: {
      get() {return this.$store.getters.user},
    },

  },
  methods: {
    serSrc(state) {
      this.category_name = state
      this.defaultParameters = 'Images';
    },
    getSelectItems(category) {
      if (category === 'EpidemicSound') {
        return ['Music'];
      } else if (category === 'ArtList.io') {
        return ['Music'];
      } else if (category === 'shutterstock') {
        return ['Images', 'Video', 'Music'];
      }  else if (category === 'freepikvideo') {
        return ['Video'];
      } else if (['adobestock', 'istockphoto'].includes(category)) {
        return ['Images', 'Video'];
      } else {
        return ['Images'];
      }
    },
    sendRequest() {
      let data = {
        action: 'Getting',
        id: this.id.toString(),
        category_name: this.category_name,
        type_name: this.defaultParameters,
        code: null,
        options: this.defaultParameters === 'Video' && this.options == null ? 'HD' : this.options,
        us: this.$store.getters.user.id,
      };
      if (data.id && data.type_name && data.category_name && data.category_name !== true) {
        let regex = '';
        let match = '';

        if (data.category_name !== 'Other') {
          switch (data.category_name) {
            case 'element_envato':
            case 'alamy':
              data.id = data.id.match(/([A-Z0-9A-Z]{3,10})/g);
              break;
            case 'epidemicsound':
            case 'EpidemicSound':
              data.type_name = 'Music';
              data.id = data.id.match(/([A-Za-z0-9]{6,13})/g);
              data.id = this.removeA(data.id, "epidemicsound");
              break;
            case 'pixelsquid':
              regex = /([0-9]{6,22}).*?image=([^&]+)/;
              match = data.id.match(regex);

              if (match && match[1] && match[2]) {
                data.id = [match[1]];
                data.code = match[2];
              } else {
                console.error("Failed to extract id and code from the URL.");
              }
              break;
            default:
              data.id = data.id.match(/([0-9]{6,19})/g);
              break;
          }

          if (data.id) {
            const uniquePreviews = data.id.filter((id, index) => {
              return (
                  data.id.indexOf(id) === index && this.isPreviewUnique(data.category_name, data.type_name, id.toString(), data.options)
              );
            });

            if (uniquePreviews.length > 0) {
              uniquePreviews.forEach(id => {
                this.$store.commit('preview/SET_PRE_PREVIEWS', {
                  action: data.action,
                  id: id.toString(),
                  category_name: data.category_name,
                  type_name: data.type_name,
                  code: data.code,
                  options: data.options,
                  us: data.us,
                });
              });
              this.$store.dispatch("preview/addPreview", data);
            }
          }
        }
      }
    },
    removeA(arr, value) {
      return arr.filter(item => item !== value);
    },
    isPreviewUnique(categoryName, typeName, id, options) {
      const previews = this.$store.getters['preview/getPreviews'];
      return !previews.some(
          preview =>
              preview.category_name === categoryName &&
              preview.type_name === typeName &&
              preview.options === options &&
              preview.id === id
      );
    },
    addItem(item) {
      this.category_name = item;
      this.defaultParameters = 'Images';
      const removed = this.items.splice(0, 1)
      this.items.push(
          ...this.more.splice(this.more.indexOf(item), 1),
      )
      this.more.push(...removed)
      this.$nextTick(() => { this.currentItem = 'tab-' + item })
    },

  }
}
</script>
