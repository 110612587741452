<template>
  <v-expansion-panels focusable>
    <v-expansion-panel v-for="(item, i) in items" :key="i">
      <v-expansion-panel-header>{{ $t('autoPaymentByAI')}} {{ item.type }}</v-expansion-panel-header>
      <v-expansion-panel-content>
        <p>{{ item.type }} <b>{{ item.value }}</b></p>
        <p>{{$t('afterPayment')}}</p>
        {{$t('creditingTime')}}
        <v-container fluid>
          <v-row>
            <v-col cols="4">
              <v-subheader><small>{{ $t('enterTransferAmount')}}</small></v-subheader>
              <v-subheader v-if="convertedsub">{{$t('willGetAmmount',{value: convertedsub})}}</v-subheader>
            </v-col>
            <v-col cols="8">
              <v-text-field
                  :label="$t('minimumTransferAmount',{ currency: item.currency, value: item.minimal})"
                  v-model="amount"
                  @focus="activeField = item.currency"
                  @blur="activeField = null"
                  :mask="['##################']"
              ></v-text-field>
              <v-file-input
                  accept="image/*"
                  :label="$t('uploadPaymentReceipt')"
                  ref="file"
                  multiple
                  chips
                  color="pink"
                  v-model="files"
                  @change="addFiles"
              ></v-file-input>
              <v-btn
                  class="mr-4"
                  color="light-green"
                  :disabled="!validateMinimumTransfer(item.minimal)"
                  @click="submit(items[i])"
              >
                {{ $t('confirmTransfer') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>

</template>

<script>

export default {
  name: "paymentInfo",
  data() {
    return {
      currentFile: undefined,
      hover: false,
      allow: true,
      progress: 0,
      sum: 0,
      activeField: null, // Добавьте переменную для отслеживания активного поля
      convertedsub: null,
      rateDatas: null,
      dialog: false,
      files: [],
      imgRef: '',
      readers: [],
    };
  },

  watch: {
    sum: {
      handler(newRubAmount) {
        try {
          this.rateDatas = this.rate;
          const userCurrency = this.$store.getters.userCurrency;

          if (typeof userCurrency === 'string' && userCurrency.trim() !== '') {

            if (userCurrency !== 'UZS'){
              if (this.activeField === 'UZS'){
                const buyPrice = this.rateDatas[userCurrency].buy_price;
                this.convertedsub = newRubAmount / buyPrice

              }else {
                if (this.activeField === userCurrency){
                  this.convertedsub = newRubAmount
                }else {
                  const buyPrice = this.rateDatas[this.activeField].buy_price;
                  const curval = this.rateDatas[userCurrency].cell_price;
                  this.convertedsub = newRubAmount * (buyPrice / curval).toFixed(2)
                }
              }
            }else {
              if (userCurrency === this.activeField){
                this.convertedsub = newRubAmount
              }else {
                const buyPrice = this.rateDatas[this.activeField].buy_price;
                this.convertedsub = buyPrice * newRubAmount
               }
            }
          } else {
            console.log('userCurrency равен null, undefined или пустой строке, выполняйте необходимые действия');
          }
        }catch (e) {
          console.log(e.message)
        }
      },
      immediate: true,
    },
  },
  components:{
  },
  props: ['items'],
  methods: {
    getTimestampInSeconds() {
      return Math.floor(Date.now() / 1000);
    },
    submit(data) {
      try {
        this.allow = false;
        const users = this.$store.getters.user;

        // Проверка наличия выбранного файла
        if (!this.currentFile) {
          throw new Error(this.$t('NoFileSelected'));
        }
        // Проверка наличия объектов с status_id: 1 в new_request и all_request
        const newRequest = this.$store.getters['payment/getNewRequest'];
        const allRequest = this.$store.getters['payment/getAllRequest'];

        if (
            (newRequest && newRequest.status_id === 1) ||
            (allRequest && allRequest.some(request => request.status_id === 1))
        ) {
          throw new Error(this.$t('OpenRequestsExist'));
        }
        let transfers = {
          user_date: this.getTimestamp(),
          user_email: users.email,
          user_balance: users.balance,
          file: this.files,
          amount: parseInt(this.sum),
          currency: data.currency,
          type: data.type,
          uid: this.generateNumber(data.type),
          type_id: data.id,
          status_id: 1,
          us: users.id,
        };

        const regex = /(\d+)/; // This regular expression matches one or more digits
        const matches = data.minimal.match(regex);

        if (isNaN(transfers.amount) || transfers.amount < matches[0]) {
          throw new Error(this.$t('minimumTransferAmount', { value: data.minimal, currency: data.currency }).toString());
        }

        this.$store.commit('payment/SET_NEW_REQUEST', transfers);
        let formData = new FormData();
        const config = {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        };

        // Добавление данных к форме только при наличии текущего файла
        formData.append('amount', transfers.amount);
        formData.append('currency', transfers.currency);
        formData.append('payment_info_id', transfers.type_id);
        formData.append('user_balance', transfers.user_balance);
        formData.append('user_email', transfers.user_email);
        formData.append('status_id', transfers.status_id);
        formData.append('file', this.currentFile[0]);
        formData.append('user_date', transfers.user_date);
        formData.append('uid', transfers.uid);
        formData.append('type', transfers.type);

        // Отправка запроса только при наличии текущего файла
        this.$store.dispatch('payment/addRequest', { data: formData, conf: config, id: users.id });
        this.allow = true;

      } catch (err) {
        this.$store.commit('setError', err.message);
        this.$store.commit('setSnackBar', true);
        this.allow = true;
      }
    },
    getTimestamp() {
      const now = new Date();
      return now.getTime(); // Возвращает временную метку в миллисекундах
    },
    generateNumber(currencyCode) {
      // Генерируем случайное 7-значное число
      const randomNumber = Math.floor(Math.random() * 9000000 + 1000000);

      // Определяем префикс в зависимости от валюты
      let prefix = '';
      switch (currencyCode) {
        case 'PerfectMoney (USD)':
          prefix = 'PM';
          break;
        case 'UZCARD':
          prefix = 'UC';
          break;
        case 'Юmoney':
          prefix = 'ЮM';
          break;
        case 'QIWI':
          prefix = 'Q';
          break;
        case 'MIR':
          prefix = 'MIR';
          break;
        case 'WMZ (Доллар США)':
          prefix = 'WMZ';
          break;
        default:
          throw new Error('Неизвестная валюта');
      }

      // Формируем номер в заданном формате
      return `${prefix}-${randomNumber}`;
    },
    async addFiles(file) {
      try {
        const allowedFormats = ['image/png', 'image/jpeg'];

        // Проверка формата файла
        if (!allowedFormats.includes(file[0].type)) {
          throw new Error(this.$t('InvalidFileFormat'));
        }

        // Проверка размера файла (1 МБ = 1024 КБ)
        const MAX_FILE_SIZE_KB = 1024;
        const file_size = file[0].size / 1024;

        if (file_size > MAX_FILE_SIZE_KB) {
          throw new Error(this.$t('FileSizeExceedsLimit'));
        }

        this.progress = 0;
        this.currentFile = file;
      } catch (error) {
        this.files = [];
        this.$store.commit('setError', error.message);
        this.$store.commit('setSnackBar', true);
      }
    },
    convert() {
      const rate = this.rate;
      const userCurrency = this.userCurrency;
      if (userCurrency && rate[userCurrency]) {
        const buyPrice = rate[userCurrency].buy_price;
        rate[userCurrency].cell_price;

        this.convertedsub = (this.rubAmount * buyPrice).toFixed(2);
      } else {
        console.error('Неверная валюта пользователя или данные о курсе.');
      }
    },
    validateMinimumTransfer(minimal) {
      const minValue = parseFloat(minimal);
      return this.sum >= minValue;
    },
  },
  computed:{
    amount:{
      get(){
        return 0
      },
      set(value){
        this.sum = value
      },
    },
    userCurrency() {
      const user = this.$store.getters["payment/getUserCur"];
      if (user) {
        return user;
      } else {
        return ''; // Возвращайте значение по умолчанию или обработку для пустого значения
      }
    },
    rate: {
      get() {
        return this.$store.getters["payment/getRate"]
      }
    },

  }
};
</script>

<style scoped>
/* Add your scoped styles here if needed */
</style>
