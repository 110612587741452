<template>
  <v-container class="fill-height pa-0">
    <v-row>
      <v-col cols="12" sm="3" class="flex-grow-1 flex-shrink-0 border-right">
        <v-responsive v-if="activeChat" class="overflow-y-hidden fill-height" height="500">
          <v-card flat class="d-flex flex-column fill-height">
            <v-card-title class="text-h6">
              {{ chatTitle }}
            </v-card-title>
            <v-card-text class="flex-grow-1 overflow-y-auto">
              <template v-for="(msg, i) in messages">
                <div :key="i" :class="{ 'd-flex flex-row-reverse': msg.me }">
                  <v-chip
                      :class="{ 'pa-4 mb-2': true, 'chat-chip': true, 'my-2': msg.me }"
                      :color="msg.me ? 'primary' : ''"
                      dark
                  >
                    {{ msg.content }}
                    <sub class="ml-2" style="font-size: 0.5rem;">{{ msg.created_at }}</sub>
                    <v-icon v-if="hover" small>
                      mdi-expand-more
                    </v-icon>
                    <!-- Добавьте условие для отображения тега "Important" -->
                    <v-chip v-if="msg.important" label color="red" class="ml-2">
                      Important
                    </v-chip>
                  </v-chip>
                </div>
              </template>
            </v-card-text>
            <v-card-text class="flex-shrink-1">
              <v-text-field
                  v-model="messageForm.content"
                  label="Введите сообщение"
                  type="text"
                  outlined
                  append-outer-icon="mdi-send"
                  @keyup.enter="sendMessage"
                  @click:append-outer="sendMessage"
                  hide-details
              />
            </v-card-text>
          </v-card>
        </v-responsive>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'OrdersPage',
  data() {
    return {
      activeChat: 1,
      // Добавьте свойства chatTitle и hover
      chatTitle: 'Чат с Тех. Подержкой', // Установите заголовок чата
      hover: false, // Замените на нужное значение
      parents: [
        {
          id: 1,
          title: "john doe",
          active: true
        },
        {
          id: 3,
          title: "scarlett",
          active: false
        },
      ],
      messages: [
        {
          content: "lorem ipsum",
          me: true,
          created_at: "11:11am"
        },
        {
          content: "dolor",
          me: false,
          created_at: "11:11am"
        },
      ],
      messageForm: {
        content: "",
        me: true,
        created_at: "11:11am"
      }
    };
  },
  methods: {
    // ...
    sendMessage() {
      // Ваш код для отправки сообщения
    },
  },
};
</script>

<style>
.border-right {
  border-right: 1px solid rgba(0, 0, 0, 0.125); /* Цвет рамки справа */
}
.chat-chip {
  height: auto;
  white-space: normal;
}
</style>
