<template>
  <v-app>
    <v-container class="fill-height">
      <v-layout align-center justify-center>
        <v-flex xs12 sm8 md4 class="d-flex justify-center">
          <v-card class="elevation-12">
            <v-toolbar dark color="primary">
              <v-toolbar-title>Войти в систему</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <v-form v-model="valid" ref="form" validation>
                <v-text-field
                    prepend-icon="mdi-account"
                    name="email"
                    label="Email"
                    type="email"
                    :rules="emailRules"
                    v-model="email"
                    :error-messages="emailErrors"
                ></v-text-field>
                <v-text-field
                    id="password"
                    prepend-icon="mdi-lock"
                    name="password"
                    label="Password"
                    type="password"
                    :rules="passWordRules"
                    v-model="password"
                    :error-messages="passwordErrors"
                ></v-text-field>
              </v-form>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                  color="primary"
                  @click="openTelegramBot"
              >Через бот</v-btn>
              <v-spacer></v-spacer>
              <v-btn
                  color="primary"
                  @click.prevent="submit"
                  :disabled="!valid"
                  :loading="loading"
              >Войти</v-btn>
            </v-card-actions>

          </v-card>        </v-flex>
      </v-layout>
    </v-container>
    <v-snackbar
        v-model="snackBar"
        :timeout="timeout"
        color="red accent-2"
    >
      {{ $t(text) }}

      <template v-slot:action="{ attrs }">
        <v-btn
            color="white"
            text
            v-bind="attrs"
            @click="snackBar = false"
        >
          {{ $t('Close') }}
        </v-btn>
      </template>
    </v-snackbar>
  </v-app>
</template>

<script>
import {mapActions, mapState} from "vuex";
// import axios from "axios";
import router from "@/router";
export default {
  name: 'LoginPage',
  data(){
    return {
      email: '',
      password: '',
      timeout: 5000,
      valid: false,
      passWordRules: [
        v => !!v || "Пароль должен быть не менее 4 символа",
        v => (v && v.length >= 4) || "Пароль должен быть не менее 4 символа"
      ],
      emailRules: [
        v => !!v || "E-mail должен быть корректным",
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ],
    }
  },
  computed: {
    ...mapState(["token"]), // Map token from Vuex store state
    loading() {
      return this.$store.getters.loading
    },
    text() {
      return this.$store.getters.error
    },
    snackBar:{
      get() {
        return this.$store.getters.getSnackBar
      },
      set(value) {
        return this.$store.commit('setSnackBar',value)
      }
    },
    emailErrors() {
      const errors = []
      if (this.$refs.form && this.$refs.form.emailRules) {
        if (!this.$refs.form.emailRules[0](this.email)) {
          errors.push('Поле Email обязательно для заполнения')
        }
        if (!this.$refs.form.emailRules[1](this.email)) {
          errors.push('Email должен быть корректным')
        }
      }
      return errors
    },
    passwordErrors() {
      const errors = []
      if (this.$refs.form && this.$refs.form.passWordRules) {
        if (!this.$refs.form.passWordRules[0](this.password)) {
          errors.push('Поле Password обязательно для заполнения')
        }
        if (!this.$refs.form.passWordRules[1](this.password)) {
          errors.push('Пароль должен быть не менее 1 символа')
        }
        if (!this.$refs.form.passWordRules[2](this.password)) {
          errors.push('Пароль должен быть не более 10 символов')
        }
      }
      return errors
    },
  },
  components: {
  },

  methods: {
    ...mapActions(["login", "setUser"]), // Map Vuex actions
    openTelegramBot() {
      // Переход по ссылке на телеграм-бота
      window.location.href = 'https://t.me/shufflestock_bot?command=start';
    },
    async submit() {
      if (this.$refs.form.validate()) {
        this.$store.commit('setLoading', true);
        let user = {
          email: this.email,
          password: this.password,
          scope: 'influencer',
        };
        try {
          await this.login(user);
          await router.push('/');
          location.reload();
        } catch (error) {
          this.$store.commit('setLoading', false);
          // Обработка ошибки
        } finally {
          this.$store.commit('setLoading', false);
        }
      }
    },
  },
  created() {
    if (this.$route.query['loginError']) {
      this.$store.dispatch('setError', 'Please log in before proceeding');
    }
  },
};
</script>
<style>

.login-container {
  text-align: center;
}

.login-form {
  border: 1px solid #ccc;
  padding: 20px;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 300px;
}

.login-form h2 {
  margin-bottom: 15px;
}

.login-form label {
  display: block;
  margin-bottom: 5px;
}

.login-form input {
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 3px;
}

.login-form button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 3px;
  cursor: pointer;
}
</style>
