<template>
  <div>
    <h1>Конвертер валют</h1>
    <div>
      <input v-model="rubAmount" type="number" placeholder="Сумма в рублях">
      <button @click="convert">Конвертировать</button>
    </div>
    <div v-if="usdAmount !== null">
      <p>{{ rubAmount }} рублей = {{ usdAmount }} {{ userCurrency }}</p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      rubAmount: 100,
      usdAmount: null,
    };
  },
  methods: {
    convert() {
      const rate = this.rate;
      const userCurrency = this.userCurrency; // или используйте mainCur, но не оба сразу
      if (userCurrency !== 'UZS'){
        const buyPrice = rate[userCurrency].buy_price;
        const cellPrice = rate[userCurrency].cell_price;
        console.log(buyPrice)
        console.log(cellPrice)
        this.usdAmount = (this.rubAmount * buyPrice).toFixed(2);
      }
      console.log(userCurrency)

    },
  },
  computed:{
    userCurrency() {
      return this.$store.getters.userCurrency;
    },
    rate: {
      get() {
        return this.$store.getters["payment/getRate"]
      }
    }
  }
};
</script>

<style scoped>
/* Ваши стили здесь */
</style>
