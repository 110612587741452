<template>
  <select v-model="selectedLanguage" @change="changeLanguage">
    <option value="en">En</option>
    <option value="ru">Ру</option>
    <option value="uz">Uz</option>
  </select>
</template>

<script>
export default {
  data() {
    return {
      selectedLanguage: 'ru', // Значение по умолчанию
    };
  },
  methods: {
    changeLanguage() {
      // Здесь вы можете выполнить логику для изменения языка при выборе из выпадающего списка
      this.$i18n.locale = this.selectedLanguage;
    },
  },
};
</script>
