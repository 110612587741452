"use strict";

import {Downloads} from "@/classes/download";
import axios from "axios";

export default  {

    state: {
        downloads: [],
        downloading: [],
        ready: [],
    },
    actions: {
        async getDownload({ commit }, data) {
            try {
                await axios.post('v1/influencer/buy', data)
               /* const channel = `private-Purchase.${data.us}`;
                window.pusher.subscribe(channel).bind('stock_purchase', (eventData) => {
                    console.log(eventData)
                    if (eventData.success) {
                        commit('setAllow', true);
                        commit('SET_DOWNLOAD_LINK', eventData);
                        commit('preview/SET_PRE_STATUS', eventData);
                    }else {
                        commit('preview/SET_ERROR', eventData);
                    }
                });*/
                const privateChannelName = `private-Purchase.${data.us}`;
                const privateChannel = window.pusher.subscribe(privateChannelName);
                privateChannel.bind('stock_purchase', function(data) {
                    if (data.success) {
                        commit('setAllow', true);
                        commit('SET_DOWNLOAD_LINK', data);
                        commit('preview/SET_PRE_STATUS', data);
                    } else {
                        commit('SET_ERROR', data)
                    }
                });
                /*await axios.post('v1/influencer/buy', data)
                const privateChannelName = `private-Purchase.${data.us}`;
                const privateChannel = window.pusher.subscribe(privateChannelName);
                privateChannel.bind('stock_purchase', function(data) {
                    console.log(data)
                    if (data.success) {
                        commit('setAllow', true);
                        commit('SET_DOWNLOAD_LINK', data);
                        commit('preview/SET_PRE_STATUS', data);
                    } else {
                        commit('SET_ERROR', data)
                    }
                });*/
            } catch (error) {
                console.error(error);
            }
        },

    },
    mutations: {
        setCurrentDownloading (state, data){
            state.downloading.push(new Downloads(data))
        },
        SET_DOWNLOADING: (state,payload)=>{
            state.downloading.push(payload);
        },
        SET_PRE_DOWNLOAD:(state, payload)=>{
            state.downloads = payload;
        },
        SET_DOWNLOAD_LINK:(state, payload )=>{
            if ( state.downloads.length > 0){
                for (let i = 0; i <  state.downloads.length; i++) {

                    if(parseInt( state.downloads[i].image_id )=== parseInt( payload.id)) {
                        Object.assign(state.downloads[i], new Downloads(payload))

                    }
                }
            }

        },
        SET_FILE_DOWNLOADDED:(state,payload)=>{
            state.ready.push(payload)
        },
        SET_ERROR: (state,payload)=>{
            for (let i = 0; i <  state.downloads.length; i++) {
                if( state.downloads[i].id === payload.id &&
                    state.downloads[i].type_name === payload.type_name &&
                    state.downloads[i].category_name === payload.category_name) {

                    Object.assign(state.downloads[i], payload)
                }
            }
        }
    },
    methods: {

    },
    getters: {
        getDownload(state) {
            return state.downloads;
        },
        getDownloading(state) {
            return state.downloading.index;
        }


    },

}
