<template>
  <div>
    <!-- Содержимое вашей домашней страницы -->
  </div>
</template>

<script>
export default {
  name: 'instructionPage', // Изменили имя компонента
};
</script>
