import Vue from 'vue';
import VueRouter from 'vue-router';

// Импортируйте компоненты страниц для маршрутов
import HomePage from '@/views/HomePage.vue';
import LoginPage from '@/views/Auth/LoginPage.vue';
// import RegisterPage from '@/views/Auth/RegisterPage.vue';
import UserProfilePage from '@/views/User/UserProfilePage.vue';
import OrdersPage from '@/views/Orders/OrdersPage.vue';
import InstructionsPage from '@/views/Instructions/InstructionsPage.vue';
import PaymentPage from '@/views/Payment/PaymentPage.vue';
import SuccessPage from '@/views/Payment/SuccessPage.vue';
import FailPage from '@/views/Payment/FailPage.vue';
import Layout from "@/views/Layout.vue";
import FileDownload from "@/views/FileDownload.vue";
import store from '@/store'; // Assuming `isAuthenticated` is the getter in your store module

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'Layout',
        component: Layout,
        children: [
            {
                path: '',
                name: 'HomePage',
                component: HomePage,
            },
            {
                path: '/user',
                name: 'UserProfile',
                component: UserProfilePage,

            },
            {
                path: '/orders',
                name: 'Orders',
                component: OrdersPage,
            },
            {
                path: '/instructions',
                name: 'Instructions',
                component: InstructionsPage,
            },
            {
                path: '/payment',
                name: 'Payment',
                component: PaymentPage,
            },
            {
                path: '/payment/success',
                name: 'PaymentSuccess',
                component: SuccessPage,
            },
            {
                path: '/payment/fail',
                name: 'PaymentFail',
                component: FailPage,
            },
        ],
        meta: { requiresAuth: true },
    },
    {
        path: '/login',
        name: 'Login',
        component: LoginPage,
    },
    // {
    //     path: '/register',
    //     name: 'Register',
    //     component: RegisterPage,
    // },
    {
        path: '/download',
        name: 'Download',
        component: FileDownload,
    },

    // Если маршрут не найден, перенаправляем на 404
    {
        path: '*',
        redirect: '/',
    },
];

const router = new VueRouter({
    routes,
    mode: 'history',
});

router.beforeEach((to, from, next) => {
    const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
    const isAuthenticated = store.getters.isAuthenticated;
    if (requiresAuth && !isAuthenticated) {
        if (to.path !== '/login') {
            next('/login');
        } else {
            next();
        }
    } else {
        next();
    }
});

export default router;
