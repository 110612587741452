import { render, staticRenderFns } from "./UserProfilePage.vue?vue&type=template&id=6e861565&scoped=true"
import script from "./UserProfilePage.vue?vue&type=script&lang=js"
export * from "./UserProfilePage.vue?vue&type=script&lang=js"
import style0 from "./UserProfilePage.vue?vue&type=style&index=0&id=6e861565&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6e861565",
  null
  
)

export default component.exports