<template>
  <v-container>
    <section class="py-5 my-5">
      <div class="container">
        <h1 class="mb-5">{{ $t('accountSettings') }}</h1>
        <div class="bg-white  d-sm-flex">
          <div class="profile-tab-nav border-right">
            <div class="p-4">
              <div class="img-circle text-center mb-3">
                <img src="https://randomuser.me/api/portraits/lego/5.jpg" alt="Image" class="shadow">
              </div>
              <h4 class="text-center">{{user?.first_name }} {{user?.last_name }}  </h4>
              <span>{{ $t('yourBalance') }}: <b>{{ user?.balance}}.unit </b></span>

            </div>
            <div class="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
              <a class="nav-link active" id="account-tab" data-toggle="pill" href="#account" role="tab" aria-controls="account" aria-selected="true">
                <i class="fa fa-home text-center mr-1"></i>
                {{ $t('account') }}
              </a>
              <a class="nav-link" id="password-tab" data-toggle="pill" href="#password" role="tab" aria-controls="password" aria-selected="false">
                <i class="fa fa-key text-center mr-1"></i>
                {{ $t('password') }}
              </a>
<!--              <a class="nav-link" id="security-tab" data-toggle="pill" href="#security" role="tab" aria-controls="security" aria-selected="false">
                <i class="fa fa-user text-center mr-1"></i>
                {{ $t('security') }}
              </a>-->
<!--              <a class="nav-link" id="payment-tab" data-toggle="pill" href="#payment" role="tab" aria-controls="payment" aria-selected="false">
                <i class="fa fa-tv text-center mr-1"></i>
                {{ $t('payment') }}
              </a>-->
<!--              <a class="nav-link" id="notification-tab" data-toggle="pill" href="#notification" role="tab" aria-controls="notification" aria-selected="false">
                <i class="fa fa-bell text-center mr-1"></i>
                Notification
              </a>-->
            </div>
          </div>
          <div class="tab-content p-4 p-md-5" id="v-pills-tabContent">
            <div class="tab-pane fade show active" id="account" role="tabpanel" aria-labelledby="account-tab">
              <h3 class="mb-4">{{ $t('accountSettings') }}</h3>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label>{{ $t('firstName') }}</label>
                    <input type="text" class="form-control" :value="user?.first_name  ">
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label>{{ $t('lastName') }}</label>
                    <input type="text" class="form-control" :value="user?.last_name">
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label>{{ $t('email') }}</label>
                    <input type="text" class="form-control" :value="user?.email">
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label>{{ $t('phoneNumber') }}</label>
                    <input type="text" class="form-control" value="none">
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label>{{ $t('company') }}</label>
                    <input type="text" class="form-control" value="none">
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label>{{ $t('designation') }}</label>
                    <input type="text" class="form-control" value="none">
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group">
                    <label>{{ $t('bio') }}</label>
                    <textarea class="form-control" rows="4">none</textarea>
                  </div>
                </div>
              </div>
              <div>
                <button class="btn btn-primary">{{ $t('update') }}</button>
                <button class="btn btn-light">{{ $t('cancel') }}</button>
              </div>
            </div>
            <div class="tab-pane fade" id="password" role="tabpanel" aria-labelledby="applicationpassword-tab">

              <h3 class="mb-4">{{ $t('passwordSettings') }}</h3>
              <v-form
                  ref="form"
                  v-model="form.valid"
                  validation
              >
                <v-text-field
                    id="old_password"
                    prepend-icon="mdi-lock"
                    name="old_password"
                    :label="$t('oldPassword')"
                    type="password"
                    :counter="8"
                    :rules="form.passwordRules"
                    v-model="form.old_password"
                ></v-text-field>
                <v-text-field
                    id="new_password"
                    prepend-icon="mdi-lock"
                    name="new_password"
                    :label="$t('newPassword') "
                    type="password"
                    :counter="8"
                    :rules="form.passwordRules"
                    v-model="form.new_password"
                ></v-text-field>
                <v-text-field
                    id="password_confirmation"
                    prepend-icon="mdi-lock"
                    name="password_confirmation"
                    :label="$t('confirmNewPassword') "
                    type="password"
                    :counter="8"
                    :rules="form.confirmPasswordRules"
                    v-model="form.new_password_confirm"
                ></v-text-field>
              </v-form>
              <div>
                <button class="btn btn-light">{{$t('cancel')}}</button>
                <v-btn
                    color="primary"
                    @click="userPassword"
                >{{$t('update')}}</v-btn>
              </div>
            </div>
<!--            <div class="tab-pane fade" id="security" role="tabpanel" aria-labelledby="security-tab">
              <h3 class="mb-4">Security Settings</h3>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Login</label>
                    <input type="text" class="form-control">
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Two-factor auth</label>
                    <input type="text" class="form-control">
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <div class="form-check">
                      <input class="form-check-input" type="checkbox" value="" id="recovery">
                      <label class="form-check-label" for="recovery">
                        Recovery
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <button class="btn btn-primary">Update</button>
                <button class="btn btn-light">Cancel</button>
              </div>
            </div>
            <div class="tab-pane fade" id="payment" role="tabpanel" aria-labelledby="payment-tab">
              <h3 class="mb-4">Payment history</h3>
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                  <tr>
                    <th class="text-left">
                      Date
                    </th>
                    <th class="text-left">
                      Amount
                    </th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr
                      v-for="(pay, i) in balanceHistory"
                      :key="i"
                  >
                    <td>{{ pay.date }}</td>
                    <td><b>{{ pay.amount }}</b>.unit</td>
                  </tr>
                  </tbody>
                </template>
              </v-simple-table>

            </div>-->
          </div>
        </div>
      </div>
    </section>
    <div>
    </div>
  </v-container>
</template>

<script>
import axios from "axios";

export default {
  name: "UserProfile",
  data: () => ({
    perc: 0,
    progressBar: '',
    progressLabel: 'Downloading File...',
    form: {
      email: '',
      old_password: '',
      new_password: '',
      new_password_confirm: '',
      valid: false,
      new_passwordRules: [
        v => !!v || 'Password is required',
        v => (v && v.length >= 8) || 'Password must be less than 6 characters',
      ],
      new_password_confirmRules: [
        v => !!v || 'Password is required',
        v => v === this.form.password || 'Password not match',
      ],
    },
    items: [
      {
        text: 'Payment history',
      },
      {
        text: 'Payment help',
      },
      {
        text: 'Link 2',
      },
    ],
  }),
  components:{
    // Instructions
  },
  computed: {
    orders: {
      get()  {
        return this.$store.getters["orders/allOrders"]
      },
    },
    balanceHistory: {
      get()  {
        return this.$store.getters.balanceHistory
      },
    },
    user:   {
      get ()
      {
        return  this.$store.getters.user
      }
    }
  },
  watch: {},
  methods: {
    async userPassword (){
      let data = {
        old_password: this.form.old_password,
        password: this.form.new_password,
        password_confirm: this.form.new_password_confirm,
      }

      await axios.post('v1/influencer/users/password', data)
          .then(()=>{
          })
    },
  },
}
</script>
<style scoped>

@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap");
body {
  background: #f9f9f9;
  font-family: "Roboto", sans-serif;
}

.shadow {
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1) !important;
}

.profile-tab-nav {
  min-width: 250px;
}

.tab-content {
  flex: 1;
}

.form-group {
  margin-bottom: 1.5rem;
}

.nav-pills a.nav-link {
  padding: 15px 20px;
  border-bottom: 1px solid #ddd;
  border-radius: 0;
  color: #333;
}
.nav-pills a.nav-link i {
  width: 20px;
}

.img-circle img {
  height: 100px;
  width: 100px;
  border-radius: 100%;
  border: 5px solid #fff;
}
</style>