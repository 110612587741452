export class Preview {
    constructor(json = null, t = null) {
        this.action = json?.action;
        this.id = json?.id;
        this.category_name = json?.category_name;
        this.type_name = json?.type_name;
        this.code = json?.code;
        this.options = json?.options;
        this.cost = json?.cost;
        this.width = json?.width;
        this.height = json?.height;
        this.filesize = json?.filesize;
        this.type = json?.type;
        this.thumbnail = json?.thumbnail;
        this.success = json?.success;
        this.uploaded = json?.uploaded;
        this.msg = json?.msg;
        this.progress = 0;
        this.progressLabel = t ? t('DownloadingFile') : 'Downloading File ';
        this.isDownloadClicked = json?.isDownloadClicked;
    }
}