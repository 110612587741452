import Vue from 'vue'
import Vuex from 'vuex'
import shared from "@/store/shared";
import axios from "axios";
import user from "@/store/modules/user";
import navbar from "@/components/Navbar.vue";
import pagination from "@/components/PaginateControl.vue"
import preview from "@/store/modules/preview";
import dowload from "@/store/modules/downloads";
import orders from "@/store/modules/orders"
import payment from "@/store/modules/payment"

Vue.use(Vuex)
export default new Vuex.Store({
    state: {
        isAuthenticated: false,
        token: null,
        userLang: 'ru', // Значение по умолчанию
        languages: ['ru', 'en', 'uz'], // Доступные языки

    },
    mutations: {
        setAuthenticated(state) {
            state.isAuthenticated = true;
        },
        setToken(state, token) {
            state.token = token;
        },
    },
    getters: {
        isAuthenticated(state) {
            return state.isAuthenticated;
        },
        isGetToken(state) {
            return state.token;
        }
    },
    methods: {
        setUserLang(state, language) {
            if (state.languages.includes(language)) {
                state.currentLanguage = language;
            }
        },
    },
    actions: {
        initAuth({ commit }) {
            const token = localStorage.getItem('jwt');
            if (token) {
                commit('setToken', token);
                commit('setAuthenticated');
            }
        },
        async login({ commit }, user) {
            try {
                const response = await axios.post('login', user)

                const expirationDate = new Date(); // Create a new Date object
                const expiresInMinutes = 60; // Set the expiration time in minutes

                // Calculate the expiration time
                expirationDate.setTime(expirationDate.getTime() + expiresInMinutes * 60 * 1000);

                // Convert the expiration date to a string
                const expires = expirationDate.toISOString();

                const token = response.data.token;
                const dataToStore = {
                    token: token,
                    expires: expires,
                };
                commit('setToken', token);
                commit('setAuthenticated');
                localStorage.setItem('jwt', JSON.stringify(dataToStore));

            }catch (error) {

                // Обработка ошибки
                if (error.response && error.response.status === 401) {
                    console.log('AuthenticationFailed')
                    // Ошибка аутентификации на сервере
                    commit('setError','AuthenticationFailed')
                    commit('setSnackBar',true)
                    // this.errorMessage = 'Authentication failed. Please check your credentials.';
                } else {
                    // Другая ошибка
                    // this.errorMessage = 'An error occurred. Please try again later.';
                    commit('setError','AnErrorOccurred')
                    commit('setSnackBar',true)

                }
            }

        },

        async authenticateBroadcast() {
            try {
                await axios.get('v1/influencer/broadcasting/auth', {
                    auth: {
                        params: {
                            scope:'influencer',
                        },
                        headers: {
                            Authorization: `Bearer ` + this.isGetToken
                        }
                    } ,
                });
            } catch (error) {
                console.error('Broadcast authentication failed:', error);
                // Обработка ошибки аутентификации Broadcasting
            }
        },
    },
    modules: {
        preview,
        user,
        shared,
        navbar,
        dowload,
        orders,
        payment,
        pagination,
    },
})
