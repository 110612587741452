<template>
  <v-container>

    <v-navigation-drawer
        temporary
        app
        v-model="drawer"
    >

      <v-list-item>
        <v-list-item-content>

          <v-list-item-title>
            <router-link
                to="/"
                tag="span"
                class="pointer"
                style="cursor:pointer">
              <span  > MainStock</span>
            </router-link>

          </v-list-item-title>
          <v-list-item-subtitle>
            фотобанк
          </v-list-item-subtitle>
          <v-list-item two-line v-if="user">
            <v-list-item-avatar>
              <img src="https://randomuser.me/api/portraits/lego/5.jpg" alt="Image" class="shadow">
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-subtitle>{{ user?.email }}</v-list-item-subtitle>
              <v-list-item-subtitle>{{ balance }}.unit</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>

      <v-list dense>
        <v-subheader>REPORTS</v-subheader>
        <v-list-item-group
            v-model="selectedItem"
            color="primary"
        >


          <v-list-item
              v-for="link in links"
              :key="link.title"
              :to="link.url"
          >
            <v-list-item-icon>
              <v-icon v-text="link.icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-btn
                  v-if="user && link.title === $t('logout')"
                  @click="logout"
                  v-text="$t(link.title)"
              >

              </v-btn>

              <v-btn
                  v-text="$t(link.title)"
                  v-else
              >
              </v-btn>


            </v-list-item-content>
          </v-list-item>


        </v-list-item-group>

      </v-list>
    </v-navigation-drawer>
    <v-app-bar app dark color="primary">

      <v-app-bar-nav-icon
          @click="drawer = !drawer"
          class="hidden-md-and-up"
      ></v-app-bar-nav-icon>
      <router-link
          to="/"
          tag="span"
          class="pointer"
          style="cursor:pointer"
      >
        <h2>MainStock</h2>

      </router-link>

      <v-spacer> </v-spacer>


      <v-toolbar-items v-for="link in links" :key="link.title" class="hidden-sm-and-down">
        <v-btn v-if="link.title.toLowerCase() === 'logout'" float icon @click="logout" :tooltip="$t(link.title)">
          <v-icon>mdi-export</v-icon>
        </v-btn>
        <v-btn icon alt="magnify" :to="link.url" :tooltip="$t(link.title)">
          <v-icon>{{ link.icon }}</v-icon>
        </v-btn>
        <v-list-item :to="link.url" two-line v-if="link.title === 'user'">
          <v-list-item-avatar>
            <img src="https://randomuser.me/api/portraits/lego/5.jpg" alt="Image" class="shadow">
          </v-list-item-avatar>
          <v-list-item-content style="display: inline; color: #FFFFFF">
            <v-list-item-title>{{ user?.email }}</v-list-item-title>
            <v-list-item-title>{{ user?.balance }}.unit</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-toolbar-items>

<!--      <v-row>
        <v-col
            class="d-flex"
            cols="12"
            sm="6"
        >
          <v-select
              :items="items"
              label="Solo field"
              solo
          ></v-select>
        </v-col>
      </v-row>-->
      <LocaleSwitcher></LocaleSwitcher>

    </v-app-bar>

  </v-container>

</template>

<script>
import axios from "axios";
import {mapActions} from "vuex";
import i18n from "@/i18n";
import LocaleSwitcher from "@/components/LocaleSwitcher.vue";
export default {
  name: "navbar_pan",

  data() {
    return {
      drawer: false,
      selectedItem: 1,
      onLine: false,
    }
  },
  watch: {
    onLine() {
    }
  },
  components:{LocaleSwitcher},
  computed: {
    // ...mapGetters(['getTexts']),
    languages() {
      return ['ru', 'en', 'uz']; // Ваши доступные языки
    },

    user: {
      get() {
        return this.$store.getters.user
      }
    },

    selectedLanguage: {
      get() {
        return this.$store.getters.userLang
      },
      set(value) {
        this.$store.commit('updateSelectedLanguage', value); // Замените на ваш мутацию Vuex
      }
    },
    balance: {
      get() {
        return this.$store.getters.balance
      }
    },
    isUserLoggedIn: {
      get() {
        return this.$store.getters.isUserLoggedIn
      }
    },
    links() {
      if (this.isUserLoggedIn) {
        return [
          {title: 'user', icon: 'mdi-home-account', url: '/user'},
          {title: 'order', icon: 'mdi-file-download', url: '/orders'},
          // { title: 'Download', icon: 'mdi-file-download', url: '/previews' },
          {title: 'payment', icon: 'mdi-cash-multiple', url: '/payment'},
          // {title: 'instructions', icon: 'mdi-information', url: '/user/instruction'},
          {title: 'resetPassword', icon: 'mdi-restore', url: '/user'},
          {title: 'logout' },
        ]
      }
      return [
        {title: 'login', icon: 'mdi-account', url: '/login'},
        {title: 'registration', icon: 'mdi-account-lock', url: '/registration'},
      ]
    }
  },
  action: {},
  methods: {
    ...mapActions(['changeLanguage']),
    async logout() {
      try {
        await axios.post('v1/influencer/logout');
        localStorage.removeItem('jwt');
        await this.$router.replace('/login');
      } catch (error) {
        console.error('Ошибка при выходе:', error);
        // Обработка ошибок, например, вывод сообщения об ошибке
      }
    },
    changeLanguage() {
      // i18n.locale = this.selectedLanguage;
      axios.post('v1/influencer/users/lang', { lang: this.selectedLanguage })
          .then(() => {
            i18n.locale = this.selectedLanguage

          })
          .catch(error => {
            console.log(error)
          });
    },
    closeError() {
      this.$store.dispatch('cleanError')
    },
    navigate() {
      // Перенаправить пользователя на главную страницу
      this.$router.push('/');
    }
  },
  getters: {},
}
</script>
<style>
.custom-select {
  /* Здесь можно определить желаемые стили для выпадающего списка */
  background-color: #fff;
  color: #333;
  border: 1px solid #ccc;
  padding: 5px;
  border-radius: 4px;
  width: 50px; /* Ширина выпадающего списка */
  cursor: pointer;
}
</style>
