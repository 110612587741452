<template>
  <v-container>
    <section class="py-5 my-5">
      <div class="container">
        <h1>{{ $t('autoPaymentByAI') }}</h1>
        <paymentInfo :items="items"></paymentInfo>
        <br>
        <h3 class="mb-4">{{ $t('requestedPayment') }}</h3>
        <requestPayment :all_request="all_request" ></requestPayment>
      </div>
    </section>
    <div>
    </div>

    <v-snackbar
        v-model="snackBar"
        :timeout="timeout"
        color="red accent-2"
    >
      {{ $t(text) }}

      <template v-slot:action="{ attrs }">
        <v-btn
            color="white"
            text
            v-bind="attrs"
            @click="snackBar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>

</template>

<script>
import requestPayment from "@/components/payment/requestPayment.vue";
import paymentInfo from "@/components/payment/paymentInfo.vue";

export default {
  name: 'PaymentPage',
  mounted() {
    this.$store.dispatch('payment/getRequest')
    this.$store.dispatch('payment/getPaymentInfo')
    this.$store.dispatch('payment/exchangeRate')
  },
  components: {
    requestPayment,
    paymentInfo,
  },

  data: () => ({
    currentFile: undefined,
    hover: false,
    progress: 0,
    amount: '',
    dialog: false,
    allow: true,
    files: [],
    imgRef: '',
    readers: [],
    chat: false,
    timeout: 5000,
    checkbox: false,
  }),

  computed: {
    text:{
      get() {

        return this.$store.getters.error
      }
    },
    snackBar:{
      get() {
        return this.$store.getters.getSnackBar
      },
      set(value) {
        return this.$store.commit('setSnackBar',value)
      }
    },
    items: {
      get()  {
        return this.$store.getters["payment/items"]
      },
    },
    fileRequest: {
      get()  {
        return this.$store.getters.fileRequest
      },
    },
    all_request: {
      get (){
        return this.$store.getters["payment/getAllRequest"]
      }
    },
    error: {
      get (){
        this.$store.dispatch('setError',this.$store.getters["payment/getError"])
        return this.$store.getters["payment/getError"]
      }
    }
  },

  action:{

  },
  methods: {
  },
}
</script>

<style scoped>
img{
  margin-left: auto;
  margin-right: 5vw;
  background-color: #FFF;
  height: auto;
  width: 600px;
  text-align: center;
  border-radius: 8px;
}
</style>
