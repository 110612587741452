import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import axios from 'axios';
import VueCookies from 'vue-cookies';
import Pusher from 'pusher-js';
import VueI18n from 'vue-i18n';
import LanguageSelector from '@/components/LanguageSelector.vue';
import Footers_vue from '@/components/Footers_vue.vue';
import MoneyConverter from '@/components/MoneyConverter.vue';
import FileDownload from '@/views/FileDownload.vue';
import HomePage from '@/views/HomePage.vue';
import SupportChat from '@/components/Chat/SupportChat.vue';

Vue.component('LanguageSelector', LanguageSelector);
Vue.use(VueI18n);

const initialLocale = store.getters.userLang || 'ru';

const i18n = new VueI18n({
    locale: initialLocale,
    messages: {
        en: require('./locales/en.json'),
        ru: require('./locales/ru.json'),
        uz: require('./locales/uz.json'),
    },
});

const jwtToken = localStorage.getItem('jwt');
const isAuthenticated = jwtToken !== null;

axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL;
axios.defaults.withCredentials = true;

if (isAuthenticated) {
    store.commit('setAuthenticated', isAuthenticated);
    axios.defaults.headers.common['Authorization'] = `Bearer ${jwtToken}`;
}

// Axios interceptor to set Authorization header for every request
axios.interceptors.request.use((config) => {
    if (isAuthenticated) {
        config.headers.Authorization = `Bearer ${jwtToken}`;
    }
    return config;
});

window.pusher = new Pusher(process.env.VUE_APP_PUSHER_KEY, {
    broadcaster: 'pusher',
    key: process.env.VUE_APP_PUSHER_KEY,
    cluster: 'ap2',
    encrypted: true,
    authEndpoint: process.env.VUE_APP_AUTH_ENDPOINT,
    auth: {
        params: {
            scope: 'influencer',
        },
        headers: {
            Authorization: `Bearer ${jwtToken}`,
        },
    },
    authorizer: (channel) => {
        return {
            authorize: (socketId, callback) => {
                axios
                    .post(
                        'v1/influencer/broadcasting/auth',
                        {
                            socket_id: socketId,
                            channel_name: channel.name,
                        },
                        {
                            params: {
                                scope: 'influencer',
                            },
                            headers: {
                                Authorization: `Bearer ${jwtToken}`,
                            },
                        }
                    )
                    .then((response) => {
                        callback(false, response.data);
                    })
                    .catch((error) => {
                        callback(true, error);
                    });
            },
        };
    },
});



Pusher.logToConsole = false;

Vue.use(VueCookies);
Vue.config.productionTip = false;

// Register components
Vue.component('main-page', HomePage);

store.dispatch('initAuth').then(() => {
    new Vue({
        router,
        store,
        vuetify,
        SupportChat,
        i18n,
        Footers_vue,
        MoneyConverter,
        FileDownload,
        render: (h) => h(App),
        components: {},
    }).$mount('#app');
});
