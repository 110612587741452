import { render, staticRenderFns } from "./FileDownload.vue?vue&type=template&id=5c69c484&scoped=true"
var script = {}


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5c69c484",
  null
  
)

export default component.exports