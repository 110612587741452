// import {User} from "@/classes/user";


export default {

    state: {
        user: null,
        userloc: null,
        balance: null,
        settings: {},
    },
    mutations: {

        SET_USER:  (state, user) => state.user = user,
        SET_USER_LOC: (state, loc) => state.userloc = loc,
        updateSelectedLanguage: (state, lang) => state.user.lang = lang,
        SET_USER_BALANCE: (state, bal) => state.balance = bal,
        setSettings(state, settings) {
            state.settings = settings;
        },
    },

    actions: {

        async setUserBalance(context, bal){
            await context.commit('SET_USER_BALANCE', bal)
        },

    },
    getters: {
        user (state) {
            return state.user;
        },
        userLang (state) {
            return state.user?.lang;
        },
        balance (state) {
            return state.user.balance;
        },
        isUserLoggedIn (state) {
            return state.user !== null;
        },
        userCurrency(state) {
            return state.user.currency_code;
        },
    },

};