<template>
  <v-layout class="col-xs-12 col-sm-12 col-md-12 col-lg-9 ">
    <v-flex>
      <v-simple-table class="custom-table" fixed-header store="preview" collection="previews" v-if="buyingPreview.length > 0">
        <thead>
        <tr>
          <th class="text-center"><span v-html="$t('Select')"></span></th>
          <th class="text-center"><span v-html="$t('Number')"></span></th>
          <th class="text-center"><span v-html="$t('Name')"></span></th>
          <th class="text-center"><span v-html="$t('ID')"></span></th>
          <th class="text-center"><span v-html="$t('Preview')"></span></th>
          <th class="text-center"><span v-html="$t('Info')"></span></th>
          <th class="text-center"><span v-html="$t('Action')"></span></th>
        </tr>
        </thead>
        <tbody>
        <tr style="height: 88px;" v-for="(item, index) in buyingPreview" :key="index">

          <td>
            <v-checkbox v-model="selectedItem" :value="item"></v-checkbox>
          </td>
          <td>{{ index + 1 }}</td>
          <td>{{ item.category_name }}</td>
          <td>{{ item.id }}</td>
          <td>
            <img
                class="centered-image"
                style="border: 1px solid #1abc9c; max-height: 83px; max-width: 138px"
                :src="item.thumbnail"
                @click="openEnlargedModal(item.thumbnail)"
            />
<!--            <v-img class="centered-image" style="border: 1px solid #1abc9c;" contain :lazy-src="item.thumbnail" :src="item.thumbnail"></v-img>-->
          </td>

          <td v-if="item.option">
            <form>
              <input type="radio" id="option1" name="options" value="HD">
              <label for="option1"> HD </label><br>
              <input type="radio" id="option2" name="options" value="4K">
              <label for="option2"> 4K </label><br>
            </form>
          </td>
          <td v-if="item.success">
            <form>
              <span v-if="item.type" v-html="$t('Type', { type: item.type })"></span>
              <span v-if="item.width" v-html="$t('Width', { width: item.width })"></span>
              <span v-if="item.height" v-html="$t('Height', { height: item.height })"></span>
              <span v-if="item.filesize" v-html="$t('Filesize', { filesize: item.filesize })"></span>
              <span v-if="item.code" v-html="$t('Code', { code: item.code })"></span>
              <span v-html="$t('Price', { cost: item.cost})"></span>

            </form>
          </td>
          <td  v-if="item.success === false">
            <p>{{ $t(item.msg) }}</p>
          </td>
          <td class="justify-center align-content-center align-center"
              v-if="item.action === 'Buy' || item.action === 'msg'">

            <v-btn
                color="error"
                dense
                @click.prevent="removePreview(item, index)"
            >
              <v-icon>
                mdi-trash-can
              </v-icon>
            </v-btn>
          </td>

          <td v-if="item.action === 'Getting'">
            <v-progress-circular
                :size="30"
                color="primary"
                indeterminate
            ></v-progress-circular>
          </td>
          <td v-if="item.action === 'Buying'">
            <v-progress-circular
                :size="30"
                color="red"
                indeterminate
            ></v-progress-circular>
          </td>
          <td v-if="item.action === 'Purchased' ">
            <v-btn
                v-if="item.uploaded === 1"
                dense
                @click.prevent="downloadLing(item,index)"
                class="mb-3"
            >
              {{ $t("Download") }}
              <v-icon
                  dense
                  x-large
                  :color="item.isDownloadClicked ? 'gray' : 'green'"
              >
                mdi-cloud-download
              </v-icon>
            </v-btn>

            <v-btn
                v-if="item.uploaded === 0"
                dense
                @click.prevent="clickedDownload(item.id, item.category_name, item.type_name, index)"
                class="mb-3"
            >
              {{ $t( item.action) }}
              <v-icon
                  dense
                  x-large
                  :color="item.isDownloadClicked ? 'gray' : 'green'"
              >
                mdi-cloud-download
              </v-icon>
            </v-btn>
            <div
                v-if="item.uploaded === 0 && item.progressBar"
                style=" display: flex; flex-direction:row; justify-content: center;"
                v-bind:id="index"
                v-bind:class="'progress-indicator-' + index">

              <div style="width: 200px"
                   class="d-flex flex-column justify-center align-center text-center align-content-center justify-center">
                <p class="justify-center"
                   style="margin: 0; line-height: 100%; font-size: 14px; display: flex; justify-content: space-between;">
                  <strong class="mb-1">{{ $t('DownloadingFile') }}</strong>
                  <span>{{ item.progress }}%</span>
                </p>
                <v-progress-linear
                    v-bind:class="'progress-bar-' + index"
                    max="100"
                    :value="item.progress"
                    :color=getColorBasedOnProgress(item.progress)
                    :style="{
                      'background': 'pink', // Используйте динамический цвет
                      'height': '4px',
                      'border-radius': '2px',
                      'overflow': 'hidden',
                      'width': '100%'
                    }"
                ></v-progress-linear>
              </div>
            </div>
          </td>
        </tr>
        </tbody>
      </v-simple-table>
      <hr  v-if="buyingPreview.length > 0">
      <v-simple-table v-if="buyingPreview.length > 0" fixed-header>
        <template v-slot:default>
          <tr>
            <td>
              <v-checkbox v-model="allSelected" :value="true" :label="$t('SelectAll')"></v-checkbox>
            </td>
            <td>
              <span v-html="$t('TotalOrders', { count: buyingPreview.length })"></span>
            </td>
            <td>
              <span v-html="$t('Selected', { count: selectedItem.length })"></span>
            </td>
            <td>
              <span v-html="$t('TotalAmount', { amount: selectedPrice })"></span>
            </td>
            <td>
              <v-btn @click.prevent="getBuyItem(selectedItem)" class="d-flex align-items-center btn btn-success text ma-2" color="primary" :disabled="allow === false">
                {{ $t('BuySelected') }}
              </v-btn>
            </td>

          </tr>

<!--          <tr>
            <td>
              <v-checkbox v-model="allSelected" :value="true" label="Select All"></v-checkbox>
            </td>
            <td>

              <span>{{ $t('TotalOrders', { count: buyingPreview.length }) }}</span>
            </td>
            <td>
              <span v-html="$t('Selected', { count: selectedItem.length })"></span>
            </td>
            <td>
              <span v-html="$t('TotalAmount', { amount: selectedPrice })"></span>

            </td>
            <td>
              <v-btn @click.prevent="getBuyItem(selectedItem)" class="d-flex align-items-center btn btn-success text ma-2" color="primary" :disabled="allow === false">
                Buy Selected
              </v-btn>
            </td>
          </tr>-->
        </template>
      </v-simple-table>


    </v-flex>
    <v-dialog v-model="showModal" max-width="800px">
      <v-card>
        <v-card-actions>
          <v-btn icon @click="closeEnlargedModal">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-actions>
        <v-card-text>
          <img :src="enlargedImage" style="max-width: 100%; margin: 0 auto; display: block" />
        </v-card-text>
      </v-card>
    </v-dialog>

  </v-layout>
</template>
<script>


// import axios from "axios";
import {mapGetters} from "vuex";
import axios from "axios";

export default {

  data() {
    return {
      showModal: false,
      enlargedImage: '',
      dialog: false,
      perc: '',
      isLandscape: false,
      user: '',
      type: '',
      selectedCount: '',
      hover: false,
      selectedPrice: '',
      // selectedItem: [],
      downloads: [],
      allSelected: false,
      buyClicked: [],
      timeout: 5000,
      progressLabel: 'Downloading File...',
      code: [],
      codes: [
        code => !!code || 'Required.',
        code => (code || '').length <= 20 || 'Max 10 characters',
        code => {
          const pattern = /^[a-zA-Z0-9]{2,}$/
          return pattern.test(code) || 'Invalid code.'
        },
      ],
      codeDialog: false,
    }
  },
  watch: {
    selectedItem: function (value) {

      this.selectedItem = value
      this.selectedCount = this.selectedItem.length
      this.selectedPrice = this.selectedItem.map(({cost}) => (cost))

      this.selectedPrice = this.selectedPrice.reduce((a, b) => parseInt(a) + parseInt(b), 0)
      let balance = this.$store.getters.user.balance
      this.allow = balance >= this.selectedPrice;
    },
    allSelected: function (value) {
      if (value) {
        this.selectedCount = this.buyingPreview.length
        this.selectedItem = this.buyingPreview

        let total = this.selectedItem.map(({cost}) => (cost))

        this.selectedPrice = total.reduce((a, b) => parseInt(a) + parseInt(b), 0)
        let balance = this.$store.getters.user.balance
        this.allow = balance >= this.selectedPrice;
      } else {
        this.selectedCount = 0
        this.selectedPrice = 0
        this.selectedItem = []
      }
    },
    dialog(val) {
      if (!val) return
      setTimeout(() => (this.dialog = false), 4000)
    },
    options: {
      handler() {
      },
      deep: true,
    },
  },
  props: ['src', 'alt', 'size'],
  computed: {

    allow: {
      get() {
        return this.$store.getters.allow
      },
      set(item) {
        this.$store.commit('setAllow', item)
      }
    },
    selectedItem: {
      get() {
        return this.$store.getters["preview/getselectedItem"]
      },
      set(item) {
        this.$store.commit('preview/SET_SELECT_ITEM', item)
      },
    },
    ...mapGetters({
      buyingPreview: 'preview/getPreviews',
      getDownloading: 'getDownloading',
      loading: 'loading',
      notification: 'error',
    }),
    shouldRenderPreviewComponent() {
      // Retrieve previews from local storage
      const previews = JSON.parse(localStorage.getItem('previews')) || [];

      // Check if there is an object with action "Buy" in previews
      return previews.some(preview => preview.action === 'Buy');
    }
  },
  methods: {
    openEnlargedModal(imageUrl) {
      this.enlargedImage = imageUrl;
      this.showModal = true;
    },
    closeEnlargedModal() {
      this.showModal = false;
      this.enlargedImage = '';
    },
    radioType(item, code) {
      this.$store.commit('preview/SET_SELECTED_ITEM_CODE', {id: item.id, code: code, costs: item.cost[code]})

      return code
    },
    async getBuyItem(item) {
      const dow = item.map((preview) => {
        const isPixelsquid = preview.category_name === 'pixelsquid';
        return {
          action: 'Buying',
          id: preview.id,
          category_name: preview.category_name,
          type_name: preview.type_name,
          code: isPixelsquid ? this.$refs.inputField[preview].value : null,
          options: preview.options,
          success: preview.success,
          uploaded: preview.uploaded,

        };
      });

      this.$store.commit('preview/SET_PRE_STATUS_BUYING', dow);
      await this.$store.dispatch('setAllow', false);
      dow.us = this.$store.getters.user.id
      const balance = this.$store.getters.balance;
      await this.$store.dispatch('setUserBalance', balance - this.selectedPrice);
      await this.$store.dispatch('getDownload', dow)

    },
    downloadLing(url,index) {
      this.buyingPreview[index].isDownloadClicked = true;
      window.open(url.downloadLink)
    },
    async clickedDownload(id, category_name, type_name, index) {
      try {
        const preview = this.buyingPreview[index];
        this.buyingPreview[index].progressBar = true
        const data = {
          id: id,
          category_name: category_name,
          type_name: type_name,
          index: index,
          progressBar: true,
          options: preview.options,
          code: preview.code,
        };

        this.$store.commit('preview/setCurrentDownloading', data);
        axios.post(`v1/influencer/download`, data, {
          responseType: 'blob',
          onDownloadProgress: progressEvent => {
            const total = parseFloat(progressEvent.total);
            const current = progressEvent.loaded;
            const percentCompleted = Math.floor(current / total * 100);

            this.loadPro(percentCompleted, index);
          }
        })
            .then(response => {
              const typeToExtensionMap = {
                'x-eps': 'eps',
                'postscript': 'ai',
                'pdf': 'ai',
                'quicktime': 'mov',
                'jpg': 'jpeg', // Добавили соответствие для 'jpg'
                'jpeg': 'jpeg', // Добавили соответствие для 'jpeg'
                'png': 'png', // Добавили соответствие для 'png'
                'svg+xml': 'svg', // Добавили соответствие для 'svg'
                'vnd.adobe.photoshop': 'psd',
                'gif': 'gif',
                'bmp': 'bmp',
                'mp4': 'mp4',
                'x-msvideo': 'avi',
                'x-matroska': 'mkv',
                'x-ms-wmv': 'wmv',
                'x-flv': 'flv',
                'webm': 'webm',
                'zip': 'zip',
                'x-rar-compressed': 'rar',
                'tiff': 'tiff',
                'tif': 'tif',
                // Добавьте другие соответствия здесь
              };
              const extensionMatch = response.data.type.match(/(eps|jpeg|jpg|psd|png|mov|mkv|mp4|zip|rar|wav|mp3|bmp|tiff|mpeg|postscript|ai|pdf|quicktime)/);
              const extensionKey = extensionMatch[0];
              let actualExtension = typeToExtensionMap[extensionKey] || extensionKey;
              const file = new File([response.data], data.id + '.' + actualExtension, {
                type: response.data.type,
              });
              const fileURL = URL.createObjectURL(file);
              const fileLink = document.createElement('a');
              fileLink.href = fileURL;
              fileLink.download = file.name;
              document.body.appendChild(fileLink);
              fileLink.click();
              URL.revokeObjectURL(fileLink.href);
            })
            .catch(error => {
              console.log(error)
            });

      } catch (error) {
        // Обработка ошибки
        console.log(error)
      } finally {

        this.buyingPreview[index].isDownloadClicked = true; // Сбрасываем флаг нажатия
      }
    },
    loadPro(perc, index) {
      const preview = this.buyingPreview[index];
      preview.progress = perc;
      preview.progressLabel = perc < 100 ? this.$t('DownloadingFile') : this.$t('Complete');
    },
    getColorBasedOnProgress(progress) {
      if (progress < 25) {
        return '#FF0000'; // Красный цвет для значения < 25 в формате HEX
      } else if (progress < 50) {
        return '#FFA500'; // Оранжевый цвет для значения < 50 в формате HEX
      } else if (progress < 75) {
        return '#00BFFF'; // Голубой цвет для значения < 75 в формате HEX
      } else {
        return '#008000'; // Зеленый цвет для значения >= 75 в формате HEX
      }
    },
    removePreview(item, index) {
      const storedPreviews = JSON.parse(localStorage.getItem('previews')) || [];

      if (this.buyingPreview[index] === item) {
        this.buyingPreview.splice(index, 1);
        this.selectedItem.splice(index, 1);
      } else {
        let found = this.buyingPreview.indexOf(item);
        this.buyingPreview.splice(found, 1);
        this.selectedItem.splice(index, 1);
      }

      // Update localStorage by removing the item with the same ID
      const updatedPreviews = storedPreviews.filter(preview => preview.id !== item.id);
      localStorage.setItem('previews', JSON.stringify(updatedPreviews));
    }
  },
  mutations: {},
  action: {},
  getters: {}
}
</script>

<style lang="scss">

.v-progress-circular {
  margin: 1rem;
}
.centered-tr {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 88px; /* Укажите желаемую высоту для строк */
}
.custom-table {
  text-align: center; /* Горизонтальное выравнивание для всей таблицы */
}

.custom-table td,
.custom-table th {
  text-align: center; /* Горизонтальное выравнивание для всех ячеек */
  vertical-align: middle; /* Вертикальное выравнивание по центру */
}
.centered-image {
  display: block;
  margin: 0 auto; /* Горизонтальное выравнивание по центру */
  max-height: 90px;
  max-width: 170px;
  border: 1px solid #1abc9c;
}

</style>
