<template>
  <v-container>
    <div class="mt-4">
      <div class="d-flex justify-center  ">
        <v-layout class="col-xs-12 col-sm-12 col-md-12 col-lg-8 ">
          <v-flex>
            <h1>{{ $t('order') }}</h1>
            <v-simple-table class="text-center"
                            fixed-header
            >
              <template>
                <thead>
                <tr>

                  <th class="text-center"><span v-html="$t('Preview')"></span></th>
                  <th class="text-center"><span v-html="$t('ID')"></span></th>
                  <th class="text-center"><span v-html="$t('Name')"></span></th>
                  <th class="text-center"><span v-html="$t('Info')"></span></th>
                  <th class="text-center"><span v-html="$t('Action')"></span></th>

                </tr>
                </thead>

                <tbody
                    v-if="ordersData?.length  !== 0"
                >

                <template

                >
                  <tr v-for="(item, index) in filteredOrders" :key="index">
                    <td>
                      <img
                          class="centered-image"
                          style="border: 1px solid #1abc9c; max-height: 83px; max-width: 138px"
                          :src="item.thumbnail"
                          @click="openEnlargedModal(item.thumbnail)"
                      />
                    </td>
                    <td>{{ item.id }}</td>
                    <td>{{ item.category_name }}</td>
                    <td>
                      <ul>
                        <li><b>{{ $t(item.type_name) }}</b></li>
                        <li v-if="item.width"><span v-html="$t('Width', { width: item.width})"></span></li>
                        <li v-if="item.height"><span v-html="$t('Height', { height: item.height})"></span></li>
                        <li v-if="item.filesize"><span v-html="$t('Filesize', { filesize: item.filesize})"></span></li>
                        <li v-if="item.type"><span v-html="$t('Type', { type: item.type})"></span></li>
                        <li v-if="item.cost"><span v-html="$t('Price', { cost: item.cost})"></span></li>
                      </ul>

                    </td>
                    <td v-if="item.success">
                      <form>
                        <span v-if="item.type" v-html="$t('Type', { type: item.type })"></span>
                        <span v-if="item.width" v-html="$t('Width', { width: item.width })"></span>
                        <span v-if="item.height" v-html="$t('Height', { height: item.height })"></span>
                        <span v-if="item.filesize" v-html="$t('Filesize', { filesize: item.filesize })"></span>
                        <span v-if="item.code" v-html="$t('Code', { code: item.code })"></span>
                        <span v-html="$t('Price', { cost: item.cost})"></span>

                      </form>
                    </td>
                    <td v-if="item.error === true">
                      <p>{{ item.message }}</p>
                    </td>
                    <td v-if="item.action === 'Purchased' " class="text-center">

                      <p class="remaining-time">
                        {{$t('available', {
                        hours: formatTime(remainingTimes[index].hours, ''),
                        minutes: formatTime(remainingTimes[index].minutes, ''),
                        seconds: formatTime(remainingTimes[index].seconds, '')
                      })}}

                      </p>
                      <v-btn
                          v-if="item.uploaded === 1"
                          dense
                          @click.prevent="downloadLing(item,index)"
                          class="mb-3"
                      >
                        {{ $t('Download') }}
                        <v-icon
                            dense
                            x-large
                            :color="item.isDownloadClicked ? 'gray' : 'green'"
                        >
                          mdi-cloud-download
                        </v-icon>
                      </v-btn>

                      <v-btn
                          v-if="item.uploaded === 0"
                          dense
                          @click.prevent="clickedDownload(item.id, item.category_name, item.type_name, index)"
                          class="mb-3"

                      >
                        {{ $t('Download') }}
                        <v-icon
                            dense
                            x-large
                            :color="item.isDownloadClicked ? 'gray' : 'green'"
                        >
                          mdi-cloud-download
                        </v-icon>
                      </v-btn>
                      <div
                          v-if="item.uploaded === 0 && item.progressBar"
                          style=" display: flex; flex-direction:row; justify-content: center;"
                          v-bind:id="index"
                          v-bind:class="'progress-indicator-' + index">

                        <div style="width: 200px"
                             class="d-flex flex-column justify-center align-center text-center align-content-center justify-center">
                          <p class="justify-center"
                             style="margin: 0; line-height: 100%; font-size: 14px; display: flex; justify-content: space-between;">
                            <strong class="mb-1">{{ $t('DownloadingFile') }}</strong>
                            <span>{{ item.progress }}%</span>
                          </p>
                          <v-progress-linear
                              v-bind:class="'progress-bar-' + index"
                              max="100"
                              :value="item.progress"
                              :color=getColorBasedOnProgress(item.progress)
                              :style="{
                      'background': 'pink', // Используйте динамический цвет
                      'height': '4px',
                      'border-radius': '2px',
                      'overflow': 'hidden',
                      'width': '100%'
                    }"
                          ></v-progress-linear>
                        </div>

                      </div>

                    </td>
                    <td v-else-if="item.action === 'Buy' ">
                      <v-btn
                          dense
                          @click.prevent="buyItem(item,index)"
                          class="mb-3"
                      >
                        {{ $t('Buy') }}
                        <v-icon
                            dense
                            x-large
                            color="red"
                        >
                          mdi-basket
                        </v-icon>
                      </v-btn>
                    </td>
                    <td v-if="item.action === 'Getting'">
                      <v-progress-circular
                          :size="30"
                          color="primary"
                          indeterminate
                      ></v-progress-circular>
                    </td>
                    <td v-if="item.action === 'Buying'">
                      <v-progress-circular
                          :size="30"
                          color="red"
                          indeterminate
                      ></v-progress-circular>
                    </td>
                  </tr>
                </template>

                </tbody>
                <tbody class="align-content-center" v-else>
                <h4>Not order yet</h4>
                </tbody>

              </template>
            </v-simple-table>
          </v-flex>

        </v-layout>
      </div>
      <div>
        <pagination store="orders" collection="order"></pagination>

      </div>
    </div>
    <v-dialog v-model="showModal" max-width="800px">
      <v-card>
        <v-card-actions>
          <v-btn icon @click="closeEnlargedModal">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-actions>
        <v-card-text>
          <img :src="enlargedImage" style="max-width: 100%; margin: 0 auto; display: block"/>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import pagination from "@/components/PaginateControl.vue"
import {mapGetters} from "vuex";
import axios from "axios";

export default {
  name: 'OrdersPage',

  data() {
    return {
      remainingTimes: [],
      timerIntervals: [],
      remainingTime: {
        hours: 0,
        minutes: 0,
        seconds: 0,
      },
      showModal: false,
      enlargedImage: '',
      dialog: false,
      perc: '',
      isLandscape: false,
      progressBar: '',
      progressLabel: 'Downloading File...',
    }
  },
  components: {
    pagination
  },
  computed: {

    ...mapGetters({
      ordersData: "orders/allOrders", // Убедитесь, что имя геттера совпадает
      getDownloading: 'getDownloading',
      loading: 'loading',
      notification: 'error',
    }),
    filteredOrders() {
      return this.ordersData.filter(item => Object.keys(item).length > 0);
    },
  },
  watch: {
    ordersData: {
      handler(newOrders) {
        // Очищаем предыдущие интервалы и сбрасываем массив оставшегося времени
        this.timerIntervals.forEach((interval) => clearInterval(interval));
        this.remainingTimes = [];

        newOrders.forEach((order, index) => {
          const endDate = order.expires_at; // Получите endDate из заказа

          // Сразу же выполните первое обновление
          this.$set(this.remainingTimes, index, this.calculateRemainingTime(endDate, index));

          const timerInterval = setInterval(() => {
            this.$set(this.remainingTimes, index, this.calculateRemainingTime(endDate, index));
            // Проверяем, истекло ли время, и изменяем action на "Buy", если да
            // Сравниваем текущее время с endDate в миллисекундах
            const currentTime = new Date().getTime();
            const endTime = new Date(endDate).getTime();

            // Проверяем, истекло ли время, и изменяем action на "Buy", если да
            if (currentTime >= endTime) {
              this.changeOrderAction(index);
            }
          }, 1000);
          this.timerIntervals.push(timerInterval);
        });
      },
      immediate: true,
    },
  },
  methods: {
    async buyItem(item) {
      const itemsArray = Array.isArray(item) ? item : [item];

      // Теперь itemsArray - это массив, и мы можем использовать метод map
      const dow = itemsArray.map((order) => {
        const isPixelsquid = order.category_name === 'pixelsquid';
        return {
          action: 'Buying',
          id: order.id,
          category_name: order.category_name,
          type_name: order.type_name,
          amount: order.cost,
          expires_at: order.expires_at,
          code: isPixelsquid ? this.$refs.inputField[order].value : null,
          options: order.options,
          // success: order.success,
        };
      });
      dow.us = this.$store.getters.user.id
      const balance = this.$store.getters.balance;
      await this.$store.dispatch('setUserBalance', balance - this.selectedPrice);
      await this.$store.dispatch('getDownload', dow)

    },
    changeOrderAction(index) {
      if (index >= 0 && index < this.ordersData.length) {
        this.$set(this.ordersData[index], 'action', 'Buy');
      }
    },
    calculateRemainingTime(endDate) {
      const now = new Date();
      const endDateTime = new Date(endDate);

      if (isNaN(endDateTime.getTime())) {
        // endDate не является допустимой датой
        return {
          hours: 0,
          minutes: 0,
          seconds: 0,
        };
      }

      const timeDifference = endDateTime - now;

      if (timeDifference <= 0) {
        // Время истекло
        return {
          hours: 0,
          minutes: 0,
          seconds: 0,
        };
      }

      const totalSeconds = Math.floor(timeDifference / 1000);
      const hours = Math.floor(totalSeconds / 3600);
      const minutes = Math.floor((totalSeconds % 3600) / 60);
      const seconds = totalSeconds % 60;

      return {
        hours: hours,
        minutes: minutes,
        seconds: seconds,
      };
    },
    formatTime(value, unit) {
      return `${value} ${unit} `;
    },
    openEnlargedModal(imageUrl) {
      this.enlargedImage = imageUrl;
      this.showModal = true;
    },
    closeEnlargedModal() {
      this.showModal = false;
      this.enlargedImage = '';
    },
    async clickedDownload(id, category_name, type_name, index) {
      try {
        const preview = this.ordersData[index];
        this.ordersData[index].progressBar = true;
        this.ordersData[index].isDownloadClicked = true;

        const data = {
          id: id,
          category_name: category_name,
          type_name: type_name,
          index: index,
          progressBar: true,
          options: preview.options,
          code: preview.code,
        };

        this.$store.commit('preview/setCurrentDownloading', data);

        const response = await axios.post(`v1/influencer/orders/file`, data, {
          responseType: 'blob',
          onDownloadProgress: progressEvent => {
            const total = parseFloat(progressEvent.total);
            const current = progressEvent.loaded;
            const percentCompleted = Math.floor((current / total) * 100);
            this.loadPro(percentCompleted, index);
          }
        });

        // Map MIME type to file extension
        const typeToExtensionMap = {
          'application/x-eps': 'eps',
          'application/postscript': 'ai',
          'application/pdf': 'pdf',
          'video/quicktime': 'mov',
          'image/jpeg': 'jpeg',
          'image/png': 'png',
          'image/svg+xml': 'svg',
          'image/vnd.adobe.photoshop': 'psd',
          'image/gif': 'gif',
          'image/bmp': 'bmp',
          'video/mp4': 'mp4',
          'video/x-msvideo': 'avi',
          'video/x-matroska': 'mkv',
          'video/x-ms-wmv': 'wmv',
          'video/x-flv': 'flv',
          'video/webm': 'webm',
          'application/zip': 'zip',
          'application/x-rar-compressed': 'rar',
          'image/tiff': 'tiff',
        };

        // Determine file extension
        const contentType = response.headers['content-type'];
        const extension = typeToExtensionMap[contentType] || 'bin';

        // Create and download file
        const file = new File([response.data], `${data.id}.${extension}`, { type: contentType });
        const fileURL = URL.createObjectURL(file);
        const fileLink = document.createElement('a');
        fileLink.href = fileURL;
        fileLink.download = file.name;
        document.body.appendChild(fileLink);
        fileLink.click();
        document.body.removeChild(fileLink);
        URL.revokeObjectURL(fileURL);
      } catch (error) {
        console.error(error);
      } finally {
        this.ordersData[index].isDownloadClicked = false;
      }
    },

    loadPro(perc, index) {
      const preview = this.ordersData[index];
      preview.progress = perc;
      preview.progressLabel = perc < 100 ? this.$t('DownloadingFile') : this.$t('Complete');
    },


    getColorBasedOnProgress(progress) {
      if (progress < 25) {
        return '#FF0000'; // Красный цвет для значения < 25 в формате HEX
      } else if (progress < 50) {
        return '#FFA500'; // Оранжевый цвет для значения < 50 в формате HEX
      } else if (progress < 75) {
        return '#FFFF00'; // Желтый цвет для значения < 75 в формате HEX
      } else {
        return '#008000'; // Зеленый цвет для значения >= 75 в формате HEX
      }
    },

    async downloadLing(item, index) {
      if (item.uploaded === 1) {
        this.ordersData[index].progressBar = false
        this.ordersData[index].isDownloadClicked = true;
        await this.$store.dispatch('orders/getOrderLink', item);
      }
    }
  },
  async created() {
    await this.$store.dispatch('orders/getOrderList', 1);
  },
  beforeDestroy() {
    // Очищаем все интервалы перед уничтожением компонента
    this.timerIntervals.forEach((interval) => clearInterval(interval));
  },
};
</script>
<style>
.centered-image {
  display: block;
  margin: 0 auto; /* Горизонтальное выравнивание по центру */
  max-height: 90px;
  max-width: 170px;
  border: 1px solid #1abc9c;
}

/* Стиль для выравнивания по левому краю внутри <td> */
td {
  text-align: left;
}

/* Убираем маркеры списка */
ul {
  list-style-type: none;
  padding-left: 0; /* Убираем отступ слева у списка */
}

.remaining-time {
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: bold;
}

.time-segment {
  margin-right: 8px;
  animation: fadeInUp 1s ease-out;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
</style>
